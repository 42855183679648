export function formatPhoneNumber(phoneNumberString) {
  const phoneArr = phoneNumberString.split("");
  phoneArr.shift();
  return "+234" + phoneArr.join("");
}

export function convertBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const stringToHslColor = (text = "", s = 50, l = 80, o = 100) => {
  const str = text.replace(" ", "");
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const h = Math.abs(hash) % 360;
  return `hsla(${h}, ${s}%, ${l}%, ${0.9})`;
};

export const formatRcDate = (date) => {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const newDate = new Date(date);
  const rcMonth = month[newDate.getMonth()];
  const rcDate = newDate.getDate();
  const rcYear = newDate.getFullYear();

  return `${rcMonth}-${rcDate}-${rcYear}`;
};

export const downloadFile = (file, fileName) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
