import SearchActionTypes from "./types";

const initialState = {
  searchResults: [],
  loadingSearchResults: false,
  error: null,
};

function searchReducer(state = initialState, { type, payload }) {
  console.log(type, payload);

  switch (type) {
    case SearchActionTypes.SET_ANY_DATA:
      return {
        ...state,
        ...payload,
      };
    case SearchActionTypes.FETCH_SEARCH_START:
      return {
        ...state,
        loadingSearchResults: true,
      };
    case SearchActionTypes.FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        loadingSearchResults: false,
        searchResults: payload,
      };
    case SearchActionTypes.FETCH_SEARCH_ERROR:
      return {
        ...state,
        loadingSearchResults: false,
        error: payload,
      };

    default:
      return state;
  }
}

export default searchReducer;
