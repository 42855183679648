import ProjectActionTypes from "./types";

const initialState = {
  projects: [],
  loadingProjects: false,
  updatingProject: false,
  creatingProject: false,
  deletingProject: false,
  error: null,
};

function projectReducer(state = initialState, { type, payload }) {
  
  switch (type) {
    case ProjectActionTypes.SET_ANY_DATA:
      return {
        ...state,
        ...payload,
      };
    case ProjectActionTypes.FETCH_PROJECTS_START:
      return {
        ...state,
        loadingProjects: true,
      };
    case ProjectActionTypes.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loadingProjects: false,
        projects: payload,
      };
    case ProjectActionTypes.FETCH_PROJECTS_ERROR:
      return {
        ...state,
        loadingProjects: false,
        error: payload,
      };

    case ProjectActionTypes.CREATE_PROJECT_START:
      return {
        ...state,
        creatingProject: true,
      };
    case ProjectActionTypes.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        creatingProject: false,
      };
    case ProjectActionTypes.CREATE_PROJECT_ERROR:
      return {
        ...state,
        creatingProject: false,
        error: payload,
      };

    case ProjectActionTypes.UPDATE_PROJECT_START:
      return {
        ...state,
        updatingProject: true,
      };
    case ProjectActionTypes.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        updatingProject: false,
      };
    case ProjectActionTypes.UPDATE_PROJECT_ERROR:
      return {
        ...state,
        updatingProject: false,
        error: payload,
      };

    case ProjectActionTypes.UPDATE_PROJECT_MEMBERS_START:
      return {
        ...state,
        updatingProject: true,
      };
    case ProjectActionTypes.UPDATE_PROJECT_MEMBERS_SUCCESS:
      return {
        ...state,
        updatingProject: false,
      };
    case ProjectActionTypes.UPDATE_PROJECT_MEMBERS_ERROR:
      return {
        ...state,
        updatingProject: false,
        error: payload,
      };

    case ProjectActionTypes.UPDATE_PROJECT_MANAGERS_START:
      return {
        ...state,
        updatingProject: true,
      };
    case ProjectActionTypes.UPDATE_PROJECT_MANAGERS_SUCCESS:
      return {
        ...state,
        updatingProject: false,
      };
    case ProjectActionTypes.UPDATE_PROJECT_MANAGERS_ERROR:
      return {
        ...state,
        updatingProject: false,
        error: payload,
      };

    case ProjectActionTypes.DELETE_PROJECT_START:
      return {
        ...state,
        deletingProject: true,
      };
    case ProjectActionTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        deletingProject: false,
      };
    case ProjectActionTypes.DELETE_PROJECT_ERROR:
      return {
        ...state,
        deletingProject: false,
        error: payload,
      };
    default:
      return state;
  }
}

export default projectReducer;
