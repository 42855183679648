import { put, takeEvery } from "redux-saga/effects";
import { createFolderSuccess, createFolderFailure, startFetchFolders } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import FolderActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* createFolder({ payload: { folder_name, cb } }) {
  const url = `${API_BASE_URL}/admin/folders`;

  const response = yield request({
    method: "post",
    data: {
      name: folder_name
    },
    url,
  });
  if (response.success) {
    yield put(createFolderSuccess());
    yield put(startFetchFolders());
    cb();
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(createFolderFailure("error"));
  }
}

export function* onCreateFolder() {
  yield takeEvery(FolderActionTypes.CREATE_FOLDER_START, createFolder);
}
