import DataEntryActionTypes from "./types";

const initialState = {
  loading: false,
  fetchingMyUploadedFiles: false,
  fetchingAllProjectsFiles: false,
  myUploadedFiles: [],
  allProjectsFiles: [],
  manuallyUploading: false,
  downloadingFile: false,
  error: null,
  uploading: false,
  renaming: false,
  deleting: false,
  makingPublic: false,
  makingPrivate: false,
};

function dataEntryReducer(state = initialState, { type, payload }) {
  switch (type) {
    case DataEntryActionTypes.SET_ANY_DATA:
      return {
        ...state,
        ...payload,
      };

    // make file public
    case DataEntryActionTypes.MAKE_FILE_PUBLIC_START:
      return {
        ...state,
        makingPublic: true,
      };
    case DataEntryActionTypes.MAKE_FILE_PUBLIC_SUCCESS:
      return {
        ...state,
        makingPublic: false,
      };
    case DataEntryActionTypes.MAKE_FILE_PUBLIC_ERROR:
      return {
        ...state,
        makingPublic: false,
        error: payload,
      };

    // make file private
    case DataEntryActionTypes.MAKE_FILE_PRIVATE_START:
      return {
        ...state,
        makingPrivate: true,
      };
    case DataEntryActionTypes.MAKE_FILE_PRIVATE_SUCCESS:
      return {
        ...state,
        makingPrivate: false,
      };
    case DataEntryActionTypes.MAKE_FILE_PRIVATE_ERROR:
      return {
        ...state,
        makingPrivate: false,
        error: payload,
      };

    case DataEntryActionTypes.DELETE_DOCUMENT_START:
      return {
        ...state,
        deleting: true,
      };
    case DataEntryActionTypes.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleting: false,
      };
    case DataEntryActionTypes.DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        deleting: false,
        error: payload,
      };

    case DataEntryActionTypes.RENAME_DOCUMENT_START:
      return {
        ...state,
        renaming: true,
      };
    case DataEntryActionTypes.RENAME_DOCUMENT_SUCCESS:
      return {
        ...state,
        renaming: false,
      };
    case DataEntryActionTypes.RENAME_DOCUMENT_ERROR:
      return {
        ...state,
        renaming: false,
        error: payload,
      };

    // fetch manual uploads
    case DataEntryActionTypes.FETCH_MY_UPLOADS_START:
      return {
        ...state,
        fetchingMyUploadedFiles: true,
      };
    case DataEntryActionTypes.FETCH_MY_UPLOADS_SUCCESS:
      return {
        ...state,
        fetchingMyUploadedFiles: false,
        myUploadedFiles: payload,
      };
    case DataEntryActionTypes.FETCH_MY_UPLOADS_ERROR:
      return {
        ...state,
        fetchingMyUploadedFiles: false,
        error: payload,
      };

    // fetch all projects files
    case DataEntryActionTypes.FETCH_ALL_PROJECTS_FILES_START:
      return {
        ...state,
        fetchingAllProjectsFiles: true,
      };
    case DataEntryActionTypes.FETCH_ALL_PROJECTS_FILES_SUCCESS:
      return {
        ...state,
        fetchingAllProjectsFiles: false,
        allProjectsFiles: payload,
      };
    case DataEntryActionTypes.FETCH_ALL_PROJECTS_FILES_ERROR:
      return {
        ...state,
        fetchingAllProjectsFiles: false,
        error: payload,
      };

    // manual upload
    case DataEntryActionTypes.MANUAL_UPLOAD_START:
      return {
        ...state,
        manuallyUploading: true,
      };
    case DataEntryActionTypes.MANUAL_UPLOAD_SUCCESS:
      return {
        ...state,
        manuallyUploading: false,
      };
    case DataEntryActionTypes.MANUAL_UPLOAD_ERROR:
      return {
        ...state,
        manuallyUploading: false,
        error: payload,
      };

    // download file
    case DataEntryActionTypes.DOWNLOAD_FILE_START:
      return {
        ...state,
        downloadingFile: true,
      };
    case DataEntryActionTypes.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloadingFile: false,
      };
    case DataEntryActionTypes.DOWNLOAD_FILE_ERROR:
      return {
        ...state,
        downloadingFile: false,
        error: payload,
      };

    default:
      return state;
  }
}

export default dataEntryReducer;
