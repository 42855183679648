import React, { useState } from "react";
import { Input, notification } from "antd";
import { NavLink } from "react-router-dom";
import "./ForgotPass.scss";
import StateLogo from "../../assets/images/logo/kano.png";
import StateMap from "../../assets/images/map/kano.png";
import urls from "../../utils/config";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const { API_BASE_URL } = urls || {};

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      submit(values);
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
  });

  const submit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${API_BASE_URL}/auth/password-reset/request`,
        data
      );
      if (res.status === 200 || res.status === 201) {
        notification.success({
          message: `Success`,
          description: `Password reset link has been sent to ${data.email}`,
          placement: "topRight",
        });
        formik.setValues(formik.initialValues);
      }
      setLoading(false);
    } catch (err) {
      notification.error({
        message: `Error`,
        description:
          err.response.data.message || "Something went wrong try again",
        placement: "topRight",
      });
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <>
        <div className="form__box">
          <div className="header">
            <h3>Reset Password</h3>
            <label>Enter your email address to get a password reset link</label>
          </div>
          <div className="form__body">
            <div className="form">
              <div className="input__container">
                <label htmlFor="email">Email address</label>
                <Input
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Email Address"
                />
                <p className="error">{formik.errors.email}</p>
              </div>
              <div className="input__container">
                <button disabled={loading} onClick={formik.handleSubmit}>
                  {loading ? (
                    <i className="icon-spin animate-spin" />
                  ) : (
                    "Request Password Link"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="forgot__password">
          <p>
            Remember your password?{" "}
            <span>
              <NavLink to="/">Log in</NavLink>
            </span>
          </p>
        </div>
      </>
    </div>
  );
};

export default ForgotPassword;
