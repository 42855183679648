import { put, takeEvery } from "redux-saga/effects";
import { deleteRequestSuccess, deleteRequestFailure, startFetchRequests } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import RequestActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* deleteRequest({ payload: { id, cb } }) {
  const url = `${API_BASE_URL}/approval-requests/${id}`;

  const response = yield request({
    method: "delete",
    url,
  });
  if (response.success) {
    yield put(deleteRequestSuccess());
    yield put(startFetchRequests());
    cb();
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(deleteRequestFailure("error"));
  }
}

export function* onDeleteRequest() {
  yield takeEvery(RequestActionTypes.DELETE_REQUEST_START, deleteRequest);
}
