import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import "./index.scss";
import { Input, Select as AntdSelect } from "antd";
import Pagination from "@mui/material/Pagination";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  selectLoadingprojects,
  selectprojects,
} from "../../../redux/project/selectors";
import Empty from "../../../assets/images/svg/empty_state.svg";
import Loader from "../../../components/Loader";
import { startFileDownload } from "../../../redux/document/actions";
import { startFetchProjects } from "../../../redux/project/actions";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import ProjectRow from "./ProjectRow";
import CreateProjectModal from "./modals/CreateProjectModal";
import UpdateProjectModal from "./modals/UpdateProjectModal";
import { EmptyState } from "../../../components/EmptyState";
import ProjectMembersModal from "./modals/ProjectMembersModal";
import ProjectManagersModal from "./modals/ProjectManagersModal";
import ConfirmProjectDeleteModal from "./modals/ConfirmProjectDeleteModal";

const Projects = () => {
  const dispatch = useDispatch();
  const projects = useSelector(selectprojects);

  const [createProjectModal, setCreateProjectModal] = useState(null);
  const [editProjectModal, setEditProjectModal] = useState(null);
  const [deleteProjectModal, setDeleteProjectModal] = useState(null);
  const [projectManagersModal, setProjectManagersModal] = useState(null);
  const [projectMembersDialog, setProjectMembersModal] = useState(null);

  const [filterOn, setFilterOn] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState(null);
  const loading = useSelector(selectLoadingprojects);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("desc");

  useEffect(() => {
    dispatch(startFetchProjects(page, limit, order, searchTerm, category));
  }, [dispatch, page, limit, order, searchTerm, category]);

  const onSearch = (val) => {
    if (val) {
      setFilterOn(true);
    } else {
      setFilterOn(false);
    }
    setSearchTerm(val);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1); // Reset to first page when limit changes
  };

  console.log(projects);

  return (
    <Layout pageTitle="Projects">
      <div className="mt-4">
        <CreateProjectModal
          title={"Create Project"}
          open={!!createProjectModal}
          closeModal={() => setCreateProjectModal(null)}
          callBack={() =>
            dispatch(
              startFetchProjects(page, limit, order, searchTerm, category)
            )
          }
        />
        <UpdateProjectModal
          title={"Update Project"}
          open={!!editProjectModal}
          closeModal={() => setEditProjectModal(null)}
          project={editProjectModal}
          callBack={() =>
            dispatch(
              startFetchProjects(page, limit, order, searchTerm, category)
            )
          }
        />
        <ProjectMembersModal
          title={"Update Project Members"}
          open={!!projectMembersDialog}
          closeModal={() => setProjectMembersModal(null)}
          project={projectMembersDialog}
          callBack={() =>
            dispatch(
              startFetchProjects(page, limit, order, searchTerm, category)
            )
          }
        />
        <ProjectManagersModal
          title={"Update Project Manager"}
          open={!!projectManagersModal}
          closeModal={() => setProjectManagersModal(null)}
          project={projectManagersModal}
          callBack={() =>
            dispatch(
              startFetchProjects(page, limit, order, searchTerm, category)
            )
          }
        />
        <ConfirmProjectDeleteModal
          title={"Delete Project"}
          open={!!deleteProjectModal}
          closeModal={() => setDeleteProjectModal(null)}
          project={deleteProjectModal}
          callBack={() =>
            dispatch(
              startFetchProjects(page, limit, order, searchTerm, category)
            )
          }
        />
        <div className="filter_bar">
          <div className="d-flex filter-inputs">
            <Input
              value={searchTerm}
              onChange={({ target: { value } }) => onSearch(value)}
              placeholder="Search projects by name"
            />
            <AntdSelect
              allowClear
              style={{
                width: "40%",
              }}
              onChange={(e) => {
                setCategory(e ? e : null);
                setFilterOn(e ? true : false);
              }}
              placeholder="Filter by Organization"
            >
              <AntdSelect.Option value="SCIDAR">SCIDaR</AntdSelect.Option>

              <AntdSelect.Option value="SOLINA_HEALTH">
                SOLINA Health
              </AntdSelect.Option>
            </AntdSelect>
          </div>

          {!loading && projects?.data?.length > 0 && (
            <Button
              onClick={() => setCreateProjectModal(true)}
              disabled={loading}
              variant="contained"
              endIcon={<AddRoundedIcon />}
              sx={{
                textTransform: "none",
              }}
            >
              Create
            </Button>
          )}
        </div>

        {loading ? (
          <Loader />
        ) : (projects?.data || [])?.length < 1 ? (
          <EmptyState
            title={"No Project Found"}
            action={() => setCreateProjectModal(true)}
            disabled={loading}
            buttonIcon={<AddRoundedIcon />}
            buttonText={"Create"}
          />
        ) : (
          <div className="table_container mt-4">
            <table>
              <thead>
                <tr>
                  <th>Project Name</th>
                  <th>Project Manager</th>
                  <th>Members</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {projects &&
                  (projects?.data || []) &&
                  (projects?.data || []).map((data, i) => (
                    <ProjectRow
                      key={data.id}
                      row={data}
                      onEditClick={(project) => {
                        setEditProjectModal(project);
                      }}
                      onDeleteClick={(project) =>
                        setDeleteProjectModal(project)
                      }
                      onProjectManagersClick={(project) =>
                        setProjectManagersModal(project)
                      }
                      onProjectMembersClick={(project) =>
                        setProjectMembersModal(project)
                      }
                    />
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && (projects?.data || [])?.length ? (
          <div className="pagination mt-4">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Pagination
                variant="outlined"
                count={projects?.meta?.lastPage || 0}
                page={page}
                onChange={handlePageChange}
                color="primary"
                shape="rounded"
              />
              <FormControl
                variant="outlined"
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <InputLabel>Items per page</InputLabel>
                <Select
                  value={limit}
                  onChange={handleLimitChange}
                  label="Items per page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default Projects;
