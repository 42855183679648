import FolderActionTypes from "./types";

const initialState = {
  folders: [],
  loadingFolders: false,
  updatingFolder: false,
  creatingFolder: false,
  deletingFolder: false,
  error: null,
};

function folderReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FolderActionTypes.SET_ANY_DATA:
      return {
        ...state,
        ...payload,
      };
    case FolderActionTypes.FETCH_FOLDERS_START:
      return {
        ...state,
        loadingFolders: true,
      };
    case FolderActionTypes.FETCH_FOLDERS_SUCCESS:
      return {
        ...state,
        loadingFolders: false,
        folders: payload,
      };
    case FolderActionTypes.FETCH_FOLDERS_ERROR:
      return {
        ...state,
        loadingFolders: false,
        error: payload,
      };

    case FolderActionTypes.CREATE_FOLDER_START:
      return {
        ...state,
        creatingFolder: true,
      };
    case FolderActionTypes.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        creatingFolder: false,
      };
    case FolderActionTypes.CREATE_FOLDER_ERROR:
      return {
        ...state,
        creatingFolder: false,
        error: payload,
      };

    case FolderActionTypes.UPDATE_FOLDER_START:
      return {
        ...state,
        updatingFolder: true,
      };
    case FolderActionTypes.UPDATE_FOLDER_SUCCESS:
      return {
        ...state,
        updatingFolder: false,
      };
    case FolderActionTypes.UPDATE_FOLDER_ERROR:
      return {
        ...state,
        updatingFolder: false,
        error: payload,
      };

    case FolderActionTypes.DELETE_FOLDER_START:
      return {
        ...state,
        deletingFolder: true,
      };
    case FolderActionTypes.DELETE_FOLDER_SUCCESS:
      return {
        ...state,
        deletingFolder: false,
      };
    case FolderActionTypes.DELETE_FOLDER_ERROR:
      return {
        ...state,
        deletingFolder: false,
        error: payload,
      };
    default:
      return state;
  }
}

export default folderReducer;
