import { createSelector } from 'reselect';

const SearchSelector = (state) => state.search;

export const selectSearchResults = createSelector(
  [SearchSelector],
  (search) => search.searchResults
);

export const selectLoadingSearchResults = createSelector(
  [SearchSelector],
  (search) => search.loadingSearchResults
);
