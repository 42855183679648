import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../../../components/Modal/modal";
import "./style.scss";
import {
  fetchMyUploadedFiles,
  fetchUploadedManualFiles,
} from "../../../../../redux/document/actions";
import { selectprojects } from "../../../../../redux/project/selectors";
import { startCreateRequest } from "../../../../../redux/request/actions";
import { selectCreatingrequest } from "../../../../../redux/request/selectors";
import {
  Autocomplete,
  darken,
  lighten,
  styled,
  TextField,
  Box,
  Typography,
  Alert,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { CircleOutlined } from "@mui/icons-material";
import LoopIcon from "@mui/icons-material/Loop";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: "#6B9A13", // text color
  backgroundColor: lighten("#6B9A13", 0.9), // color for group title
  ...theme.applyStyles("dark", {
    backgroundColor: "#6B9A10",
  }),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const RequestApprovalModal = ({ open, closeModal, title, document, callBack }) => {
  const dispatch = useDispatch();
  const submitting = useSelector(selectCreatingrequest);
  const projects = useSelector(selectprojects);
  const [project, setProject] = useState(null);

  const options = (projects?.data || []).map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const submitRequest = () => {
    if (document && project) {
      dispatch(
        startCreateRequest(document.id, project.id, () => {
          dispatch(callBack ? callBack() : fetchMyUploadedFiles());
          reset();
        })
      );
    }
  };

  const reset = () => {
    setProject(null);
    closeModal();
  };

  const selectedProjectManager =
    project && project.managers.length > 0
      ? project.managers
          .map((manager) => `${manager.firstName} ${manager.lastName}`)
          .join(", ")
      : "N/A";

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={title}
      modalFooter={
        <div className="modal_footer">
          <Button
            className="cancel me-2"
            onClick={reset}
            disabled={submitting}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={submitRequest}
            disabled={!project || !projects?.length < 1 || submitting}
            variant="contained"
            endIcon={
              submitting ? (
                <LoopIcon className="animate-spin" fontSize="medium" />
              ) : (
                <SendRoundedIcon fontSize="medium" />
              )
            }
          >
            Submit
          </Button>
        </div>
      }
      style={{ maxWidth: "600px" }} // Adjust size here
    >
      <Box>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Document: {document?.originalFilename || "No document selected"}
        </Typography>

        <div className="project_selection">
          <Autocomplete
            className="mt-2 mb-2"
            size="medium"
            options={options.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.name}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Select Project" />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            onChange={(event, newValue) => setProject(newValue)}
          />

          <Typography variant="body2" sx={{ marginTop: 1 }}>
            Project Manager: {selectedProjectManager}
          </Typography>

          {!projects.length || !projects.length < 1 && (
            <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
              Note: There are no projects associated with your account. To
              submit this directly to a project, please request to be added to a
              project.
            </Typography>
          )}
        </div>

        {project && (
          <Alert severity="info" sx={{ marginTop: 3 }}>
            After submitting the request, it will be reviewed by the project
            manager. You will be notified once the document is approved and
            added to the project.
          </Alert>
        )}

        {!project && (
          <Alert severity="warning" sx={{ marginTop: 3 }}>
            Select the project where you want this document to be stored
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default RequestApprovalModal;
