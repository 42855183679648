import { put, takeEvery } from "redux-saga/effects";
import { resetPasswordFailure, resetPasswordSuccess } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import UserActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* resetPassword({ payload: { email, cb } }) {
  const url = `${API_BASE_URL}/auth/password-reset/request`;

  const response = yield request({
    method: "post",
    data: {
      email,
    },
    url,
  });
  if (response.success) {
    yield put(resetPasswordSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "Password reset sent successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description: "Failed to send password reset",
      placement: "topRight",
    });
    yield put(resetPasswordFailure("error"));
  }
}

export function* onResetPassword() {
  yield takeEvery(UserActionTypes.PASSWORD_RESET_START, resetPassword);
}
