import { put, takeEvery } from "redux-saga/effects";
import { activateUserFailure, activateUserSuccess } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import UserActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* activateUser({ payload: { id, cb } }) {
  const url = `${API_BASE_URL}/users/${id}/activate`;

  const response = yield request({
    method: "put",
    url,
  });
  if (response.success) {
    yield put(activateUserSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "User activated successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(activateUserFailure("error"));
  }
}

export function* onActivateUser() {
  yield takeEvery(UserActionTypes.ACTIVATE_USER_START, activateUser);
}
