import { put, takeEvery } from "redux-saga/effects";
import { updateFolderSuccess, updateFolderFailure, startFetchFolders } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import FolderActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* updateFolder({ payload: { id, name, cb } }) {
  const url = `${API_BASE_URL}/admin/folders/${id}`;

  const response = yield request({
    method: "put",
    data: {
      name
    },
    url,
  });
  if (response.success) {
    yield put(updateFolderSuccess());
    yield put(startFetchFolders());
    cb();
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(updateFolderFailure("error"));
  }
}

export function* onUpdateFolder() {
  yield takeEvery(FolderActionTypes.UPDATE_FOLDER_START, updateFolder);
}
