import { put, takeEvery } from "redux-saga/effects";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import UserActionTypes from "../types";
import { createUserFailure, createUserSuccess } from "../actions";

const { API_BASE_URL } = urls || {};

export function* createUser({ payload: { data, cb } }) {
  const url = `${API_BASE_URL}/auth/sign-up`;

  const {
    firstName,
    lastName,
    email,
    phone,
    roles,
    department,
    designation,
  } = data;

  const response = yield request({
    method: "post",
    data: {
      firstName,
      lastName,
      email,
      phone,
      roles,
      department,
      designation,
    },
    url,
  });

  if (response.success) {
    yield put(createUserSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "User created successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description:
        response?.message?.message || "Something went wrong try again",
      placement: "topRight",
    });
    yield put(createUserFailure("error"));
  }
}

export function* onCreateUser() {
  yield takeEvery(UserActionTypes.CREATE_USER_START, createUser);
}
