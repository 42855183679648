const ProjectActionTypes = {
    SET_ANY_DATA: 'SET_ANY_DATA',
  
    FETCH_REQUESTS_START: 'FETCH_REQUESTS_START',
    FETCH_REQUESTS_SUCCESS: 'FETCH_REQUESTS_SUCCESS',
    FETCH_REQUESTS_ERROR: 'FETCH_REQUESTS_ERROR',

    FETCH_MYREQUESTS_START: 'FETCH_MYREQUESTS_START',
    FETCH_MYREQUESTS_SUCCESS: 'FETCH_MYREQUESTS_SUCCESS',
    FETCH_MYREQUESTS_ERROR: 'FETCH_MYREQUESTS_ERROR',

    CREATE_REQUEST_START: 'CREATE_REQUEST_START',
    CREATE_REQUEST_SUCCESS: 'CREATE_REQUEST_SUCCESS',
    CREATE_REQUEST_ERROR: 'CREATE_REQUEST_ERROR',

    APPROVE_REQUEST_START: 'APPROVE_REQUEST_START',
    APPROVE_REQUEST_SUCCESS: 'APPROVE_REQUEST_SUCCESS',
    APPROVE_REQUEST_ERROR: 'APPROVE_REQUEST_ERROR',

    DECLINE_REQUEST_START: 'DECLINE_REQUEST_START',
    DECLINE_REQUEST_SUCCESS: 'DECLINE_REQUEST_SUCCESS',
    DECLINE_REQUEST_ERROR: 'DECLINE_REQUEST_ERROR',

    DELETE_REQUEST_START: 'DELETE_REQUEST_START',
    DELETE_REQUEST_SUCCESS: 'DELETE_REQUEST_SUCCESS',
    DELETE_REQUEST_ERROR: 'DELETE_REQUEST_ERROR',
  };
  
  export default ProjectActionTypes;
  