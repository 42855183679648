import { onCreateUser } from "./createUser";
import { onGetAllMembers } from "./fetchMembers";
import { onGetAllUsers } from "./fetchUsers";
import { call, all } from "redux-saga/effects";
import { onDeleteUser } from "./deleteUser";
import { onActivateUser } from "./activateUser";
import { onDeactivateUser } from "./deactivateUser";
import { onUpdateUser } from "./updateUser_";
import { onResetPassword } from "./resetPassword";

export default function* userSaga() {
  yield all([
    call(onGetAllUsers),
    call(onGetAllMembers),
    call(onCreateUser),
    call(onUpdateUser),
    call(onDeleteUser),
    call(onActivateUser),
    call(onDeactivateUser),
    call(onResetPassword),
  ]);
}
