import { getAllRequests } from './fetchRequests';
import { call, all } from 'redux-saga/effects';
import { onCreateRequest } from './createRequest';
import { onDeleteRequest } from './deleteRequest';
import { onApproveRequest } from './approveRequest';
import { onDeclineRequest } from './declineRequest';

export default function* requestSaga() {
  yield all([
    call(getAllRequests),
    call(onCreateRequest),
    call(onApproveRequest),
    call(onDeclineRequest),
    call(onDeleteRequest),
  ]);
}
