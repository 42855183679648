import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import "./index.scss";
import { Input, Select as AntdSelect } from "antd";
import Pagination from "@mui/material/Pagination";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import ManualuploadModal from "./modals/ManualUploadModal";
import { useSelector, useDispatch } from "react-redux";
import {
  loadingAllProjectsFiles,
  selectAllProjectsFiles,
} from "../../../redux/document/selectors";
import { selectprojects } from "../../../redux/project/selectors";
import Empty from "../../../assets/images/svg/empty_state.svg";
import Loader from "../../../components/Loader";
import {
  fetchAllProjectsFiles,
  startFileDownload,
} from "../../../redux/document/actions";
import { startFetchProjects } from "../../../redux/project/actions";
import DocumentPreviewDialog from "../../../components/File-Viewer/documentPreviewDialog";
import RenameDocumentModal from "./modals/RenameDocumentModal";
import MyDocumentRow from "./ProjectDocumentRow";
import ConfirmDocumentDeleteModal from "./modals/ConfirmDocumentDeleteModal";
import RequestApprovalModal from "../DataEntry/modals/RequestApprovalModal";
import ConfirmDocumentPublicModal from "./modals/ConfirmDocumentPublicModal";
import ConfirmDocumentPrivateModal from "./modals/ConfirmDocumentPrivateModal";

const ProjectDocuments = () => {
  const dispatch = useDispatch();
  const projects = useSelector(selectprojects);
  const [manualUpload, setManualUpload] = useState(false);
  const [requestApprovalDocument, setRequestApprovalDocument] = useState(null);
  const [renameMyDocument, setRenameMyDocument] = useState(null);
  const [deleteMyDocument, setDeleteMyDocument] = useState(null);
  const [publicDocument, setPublicDocument] = useState(null);
  const [privateDocument, setPrivateDocument] = useState(null);
  const [filterOn, setFilterOn] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [preview, setPreview] = useState(null);
  const [project, setProject] = useState([]);
  const projectDocuments = useSelector(selectAllProjectsFiles);
  const loading = useSelector(loadingAllProjectsFiles);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("desc");

  useEffect(() => {
    dispatch(fetchAllProjectsFiles(page, limit, order, searchTerm, project));
    dispatch(startFetchProjects());
  }, [dispatch, page, limit, order, searchTerm, project]);

  const onSearch = (val) => {
    if (val) {
      setFilterOn(true);
    } else {
      setFilterOn(false);
    }
    setSearchTerm(val);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1); // Reset to first page when limit changes
  };

  const EmptyState = () => {
    return (
      <div className="empty_state">
        <img src={Empty} alt="" />
        <p>No File Found</p>
        <button onClick={() => setManualUpload(true)}>Upload Data</button>
      </div>
    );
  };

  const downloadFile = async (data) => {
    dispatch(startFileDownload(data.id, data.originalFilename));
  };

  return (
    <Layout pageTitle="Project Documents">
      <div className="mt-4">
        <ConfirmDocumentPrivateModal
          open={!!privateDocument}
          document={privateDocument}
          closeModal={() => setPrivateDocument(null)}
        />
        <ConfirmDocumentPublicModal
          open={!!publicDocument}
          document={publicDocument}
          closeModal={() => setPublicDocument(null)}
        />
        <ConfirmDocumentDeleteModal
          open={!!deleteMyDocument}
          document={deleteMyDocument}
          closeModal={() => setDeleteMyDocument(null)}
        />
        <RequestApprovalModal
          title="Request Approval"
          open={!!requestApprovalDocument}
          document={requestApprovalDocument}
          callBack={fetchAllProjectsFiles}
          closeModal={() => setRequestApprovalDocument(null)}
        />
        <RenameDocumentModal
          title="Rename Document"
          open={!!renameMyDocument}
          document={renameMyDocument}
          closeModal={() => setRenameMyDocument(null)}
        />
        <ManualuploadModal
          title="Manual File Upload"
          open={manualUpload}
          closeModal={() => setManualUpload(false)}
        />
        {!!preview && preview.path && (
          <DocumentPreviewDialog
            open={!!preview}
            handleClose={() => setPreview(null)}
            document={preview}
          />
        )}
        {
          <div className="filter_bar">
            <div className="d-flex filter-inputs">
              <Input
                value={searchTerm}
                onChange={({ target: { value } }) => onSearch(value)}
                placeholder="Search documents by file name"
              />
              <AntdSelect
                allowClear
                style={{
                  width: "40%",
                }}
                onChange={(e) => {
                  setProject(e ? [e] : null);
                  setFilterOn(e ? true : false);
                }}
                placeholder="Filter by Project"
              >
                {projects.data &&
                  (projects?.data || []).map((project, i) => (
                    <AntdSelect.Option key={i} value={project?.id}>
                      {project?.name.toUpperCase()}
                    </AntdSelect.Option>
                  ))}
              </AntdSelect>
            </div>
          </div>
        }
        {loading ? (
          <Loader />
        ) : projectDocuments?.data?.length < 1 ? (
          <EmptyState />
        ) : (
          <div className="table_container mt-4">
            <table>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>File Size</th>
                  <th>Projects</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {projectDocuments &&
                  projectDocuments?.data &&
                  projectDocuments?.data.map((data, i) => (
                    <MyDocumentRow
                      key={data.id}
                      row={data}
                      onEditClick={(doc) => setRenameMyDocument(doc)}
                      onDeleteClick={(doc) => setDeleteMyDocument(doc)}
                      onRequestApprovalClick={(doc) =>
                        setRequestApprovalDocument(doc)
                      }
                      onPreviewClick={(doc) =>
                        setPreview({
                          path: doc.path,
                          type: doc.type,
                        })
                      }
                      onDownloadClick={downloadFile}
                      onPublicClick={(doc) => setPublicDocument(doc)}
                      onPrivateClick={(doc) => setPrivateDocument(doc)}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && projectDocuments?.data?.length ? (
          <div className="pagination mt-4">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Pagination
                variant="outlined"
                count={projectDocuments?.meta?.lastPage || 0}
                page={page}
                onChange={handlePageChange}
                color="primary"
                shape="rounded"
              />
              <FormControl
                variant="outlined"
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <InputLabel>Items per page</InputLabel>
                <Select
                  value={limit}
                  onChange={handleLimitChange}
                  label="Items per page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default ProjectDocuments;
