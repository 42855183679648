import React from "react";
import { Select, Input } from "antd";
import Modal from "../../../../../components/Modal/modal";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import { useFormik } from "formik";
import { formatPhoneNumber } from "../../../../../helpers";
import { createUserStart } from "../../../../../redux/users/actions";
import { selectCreatingUser } from "../../../../../redux/users/selectors";

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Enter User's Name"),
  lastName: Yup.string().required("Enter User's Name"),
  email: Yup.string().email("Invalid email address").required("Enter Email"),
  phone: Yup.string()
    .optional("Enter Phone Number")
    .matches(phoneRegExp, "Phone number is not valid, Must be 11 digits"),
  roles: Yup.array().required("Select a user role"),
  department: Yup.string().required("Select a department"),
  designation: Yup.string().optional(),
});

const CreateUserModal = ({ open, closeModal, title, callBack }) => {
  const dispatch = useDispatch();
  const creatingUser = useSelector(selectCreatingUser);
  const { Option } = Select;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: undefined,
      roles: [],
      department: "",
      designation: undefined,
    },
    onSubmit: (values) => {
      const formData = {
        ...values,
        phone: values.phone && formatPhoneNumber(values.phone),
      };
      delete formData.userType;
      handleCreateUser(formData);
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
  });

  const handleCreateUser = (data) => {
    dispatch(
      createUserStart(data, () => {
        callBack();
        formik.resetForm();
      })
    );
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={title}
      modalFooter={
        <div className="modal_footer d-flex flex-wrap align-items-center justify-content-end">
          <button
            disabled={creatingUser}
            className="cancel mb-2 me-2"
            onClick={() => {
              closeModal();
              formik.setValues(formik.initialValues);
            }}
          >
            Cancel
          </button>
          <div className="mb-2">
            <button disabled={creatingUser} onClick={formik.handleSubmit}>
              Create User
              {creatingUser ? (
                <i className="icon-spin animate-spin" />
              ) : (
                <i className="icon-angle-right ms-2" />
              )}
            </button>
          </div>
        </div>
      }
    >
      <div className="mt-4 mb-4">
        <div className="form_box">
          <div className="form_container">
            <label htmlFor="">First Name</label>
            <Input
              value={formik.values.firstName}
              onChange={formik.handleChange}
              name="firstName"
              placeholder="Enter First Name"
            />
            <p className="error">{formik.errors.firstName}</p>
          </div>
          <div className="form_container">
            <label htmlFor="">Last Name</label>
            <Input
              value={formik.values.lastName}
              onChange={formik.handleChange}
              name="lastName"
              placeholder="Enter Last Name"
            />
            <p className="error">{formik.errors.lastName}</p>
          </div>
          <div className="form_container">
            <label htmlFor="">Email Address</label>
            <Input
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              placeholder="Enter email address"
            />
            <p className="error">{formik.errors.email}</p>
          </div>
          <div className="form_container">
            <label htmlFor="">Phone Number</label>
            <Input
              value={formik.values.phone}
              onChange={formik.handleChange}
              name="phone"
              type="tel"
              placeholder="Enter phone number e.g +2348043578690"
            />
            <p className="error">{formik.errors.phone}</p>
          </div>
          <div className="form_container">
            <label htmlFor="">User Role</label>
            <Select
              value={formik.values.roles}
              onChange={(e) => formik.setFieldValue("roles", [e])}
              placeholder="Select user role"
              allowClear
            >
              <Option value="GUEST">GUEST</Option>
              <Option value="NEW_STAFF">NEW STAFF</Option>
              <Option value="PROGRAM_OPERATION_STAFF">
                PROGRAM OPERATION STAFF
              </Option>
              <Option value="MANAGEMENT_STAFF">MANAGEMENT STAFF</Option>
              <Option value="SYSTEM_ADMIN">SYSTEM ADMIN</Option>
            </Select>
            <p className="error">{formik.errors.roles}</p>
          </div>
          <div className="form_container">
            <label htmlFor="">Department</label>
            <Select
              value={formik.values.department}
              onChange={(e) => formik.setFieldValue("department", e)}
              placeholder="Select department"
              allowClear
            >
              <Option value="Admin">Admin</Option>
              <Option value="Audit">Audit</Option>
              <Option value="Finance">Finance</Option>
              <Option value="HR">HR</Option>
              <Option value="IT">IT</Option>
              <Option value="Project Team">Project Team</Option>
            </Select>
            <p className="error">{formik.errors.department}</p>
          </div>
          <div className="form_container">
            <label htmlFor="">Designation</label>
            <Input
              value={formik.values.designation}
              onChange={formik.handleChange}
              name="designation"
              type="text"
            />
            <p className="error">{formik.errors.designation}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
