import { put, takeEvery } from "redux-saga/effects";
import { deactivateUserFailure, deactivateUserSuccess } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import UserActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* deactivateUser({ payload: { id, cb } }) {
  const url = `${API_BASE_URL}/users/${id}/deactivate`;

  const response = yield request({
    method: "put",
    url,
  });
  if (response.success) {
    yield put(deactivateUserSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "User deactivated successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(deactivateUserFailure("error"));
  }
}

export function* onDeactivateUser() {
  yield takeEvery(UserActionTypes.DEACTIVATE_USER_START, deactivateUser);
}
