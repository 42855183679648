import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../../../components/Modal/modal";
import "./style.scss";
import {
  TextField,
  Box,
  Alert,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { selectCreatingproject } from "../../../../../redux/project/selectors";
import { startCreateProject } from "../../../../../redux/project/actions";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

const CreateProjectModal = ({ open, closeModal, title, callBack }) => {
  const dispatch = useDispatch();
  const creating = useSelector(selectCreatingproject);

  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");

  const submitRequest = () => {
    if (projectName && description && category) {
      dispatch(
        startCreateProject(projectName, description, category, () => {
          dispatch(callBack && callBack());
          reset();
        })
      );
    }
  };

  const reset = () => {
    setProjectName("");
    setDescription("");
    setCategory("");
    closeModal();
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={title}
      modalFooter={
        <div className="modal_footer">
          <Button
            className="cancel me-2"
            onClick={reset}
            disabled={creating}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={submitRequest}
            disabled={!(projectName && description && category) || creating}
            variant="contained"
            endIcon={
              creating ? (
                <LoopIcon className="animate-spin" fontSize="medium" />
              ) : (
                <SaveRoundedIcon fontSize="medium" />
              )
            }
          >
            Save
          </Button>
        </div>
      }
      style={{ maxWidth: "600px" }}
    >
      <Box>
        <TextField
          label="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            label="Category"
          >
            <MenuItem value="SCIDAR">SCIDaR</MenuItem>
            <MenuItem value="SOLINA_HEALTH">SOLINA HEALTH</MenuItem>
          </Select>
        </FormControl>

        {!projectName || !description || !category ? (
          <Alert severity="warning" sx={{ marginTop: 3 }}>
            Please fill out all fields before submitting.
          </Alert>
        ) : (
          <Alert severity="info" sx={{ marginTop: 3 }}>
            After submitting the request, it will be reviewed and you will be
            notified once the project is created.
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default CreateProjectModal;
