import React, { useState } from "react";
import "./index.scss";
import moment from "moment";
import {
  Avatar,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledMenu } from "../../../components/Menu/styled-menu";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { stringAvatar } from "../../../components/Avatar";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";

const UserRow = ({
  row,
  onEditClick,
  onDeleteClick,
  onActivateClick,
  onDeactivateClick,
  onResetPasswordClick,
}) => {
  // MENU FOR DOCUMENTS
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // END OF MENU

  return (
    <tr>
      <td>
        <div
          id="user-col"
          style={{
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <Avatar {...stringAvatar(row.firstName)} />
          <div
            style={{
              marginLeft: 6,
            }}
          >
            <div>{`${row.firstName} ${row.lastName}`}</div>
            <span>
              Last Login:{" "}
              {row.lastLogin
                ? moment(row.lastLogin).format("MMM Do YYYY, h:mm:ss a")
                : "Never"}
            </span>
          </div>
        </div>
      </td>
      <td>
        <div>
          <div>{row.email}</div>
        </div>
        <Chip
          label={(row?.roles || [])
            .map((role) => role.replaceAll("_", " "))
            .join(", ")}
          variant="filled"
          size="small"
        />
      </td>
      <td>
        <Tooltip title={moment(row.createdAt).format("MMM Do YYYY, h:mm:ss a")}>
          <div>{moment(row.createdAt).format("MMM Do YYYY")}</div>
        </Tooltip>
      </td>
      <td>
        {row.isActive ? (
          <Tooltip
            title={`${row.firstName} ${row.lastName}'s account is active`}
          >
            <Chip
              label="Active"
              variant="filled"
              size="small"
              sx={{
                marginRight: 1,
                marginBottom: 0,
                backgroundColor: "#2e7d32",
                color: "#fff",
                "& .MuiChip-label": {
                  color: "inherit", // Force inheritance
                },
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={`${row.firstName} ${row.lastName}'s account is deactivated`}
          >
            <Chip
              label="Inactive"
              variant="filled"
              size="medium"
              sx={{
                marginRight: 1,
                marginBottom: 0,
                backgroundColor: "#d32f2f",
                color: "#fff",
                "& .MuiChip-label": {
                  color: "inherit", // Force inheritance
                },
              }}
            />
          </Tooltip>
        )}
      </td>

      <td className="action">
        <Tooltip title="Manage">
          <IconButton onClick={handleClickMenu} className="no-style">
            <MoreVertIcon
              id="basic-button"
              aria-controls={open ? "manage-user-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
          </IconButton>
        </Tooltip>
        <StyledMenu
          id="manage-user-menu"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onEditClick(row);
            }}
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />

          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onResetPasswordClick(row);
            }}
          >
            <ListItemIcon>
              <VpnKeyRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Password Reset</ListItemText>
          </MenuItem>

          {row.isActive === false && (
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onActivateClick(row);
              }}
            >
              <ListItemIcon>
                <LockOpenRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Activate</ListItemText>
            </MenuItem>
          )}

          {row.isActive === true && (
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onDeactivateClick(row);
              }}
            >
              <ListItemIcon>
                <LockRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Deactivate</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onDeleteClick(row);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </StyledMenu>
      </td>
    </tr>
  );
};

export default UserRow;
