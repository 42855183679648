import { put, takeEvery } from "redux-saga/effects";
import { fetchFoldersSuccess, fetchFoldersFailure } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import FolderActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* fetchAllFolders() {
  const url = `${API_BASE_URL}/admin/folders`;

  const response = yield request({
    method: "get",
    url,
  });
  if (response.success) {
    yield put(fetchFoldersSuccess(response?.raw.data));
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(fetchFoldersFailure("error"));
  }
}

export function* getAllFolders() {
  yield takeEvery(FolderActionTypes.FETCH_FOLDERS_START, fetchAllFolders);
}
