import DataEntryActionTypes from "./types";

// set any data
export const setAnyEntryData = (payload) => ({
  type: DataEntryActionTypes.SET_ANY_DATA,
  payload,
});

// fetch all projects files
export const fetchAllProjectsFiles = (
  page = 1,
  limit = 10,
  order = "desc",
  search = "",
  projects = null
) => ({
  type: DataEntryActionTypes.FETCH_ALL_PROJECTS_FILES_START,
  payload: { page, limit, order, search, projects },
});

export const fetchAllProjectsFilesSuccess = (data) => ({
  type: DataEntryActionTypes.FETCH_ALL_PROJECTS_FILES_SUCCESS,
  payload: data,
});

export const fetchAllProjectsFilesFailure = (error) => ({
  type: DataEntryActionTypes.FETCH_ALL_PROJECTS_FILES_ERROR,
  payload: error,
});

// fetch my uploaded files
export const fetchMyUploadedFiles = (
  page = 1,
  limit = 10,
  order = "desc",
  search = "",
  projects = null
) => ({
  type: DataEntryActionTypes.FETCH_MY_UPLOADS_START,
  payload: { page, limit, order, search, projects },
});

export const fetchMyUploadedFilesSuccess = (data) => ({
  type: DataEntryActionTypes.FETCH_MY_UPLOADS_SUCCESS,
  payload: data,
});

export const fetchMyUploadedFilesFailure = (error) => ({
  type: DataEntryActionTypes.FETCH_MY_UPLOADS_ERROR,
  payload: error,
});

// make document public
export const startMakingDocumentPublic = (id, cb) => ({
  type: DataEntryActionTypes.MAKE_FILE_PUBLIC_START,
  payload: { id, cb },
});

export const makeDocumentPublicSuccess = () => ({
  type: DataEntryActionTypes.MAKE_FILE_PUBLIC_SUCCESS,
});

export const makeDocumentPublicFailure = (error) => ({
  type: DataEntryActionTypes.MAKE_FILE_PUBLIC_ERROR,
  payload: error,
});

// make document private
export const startMakingDocumentPrivate = (id, cb) => ({
  type: DataEntryActionTypes.MAKE_FILE_PRIVATE_START,
  payload: { id, cb },
});

export const makeDocumentPrivateSuccess = () => ({
  type: DataEntryActionTypes.MAKE_FILE_PRIVATE_SUCCESS,
});

export const makeDocumentPrivateFailure = (error) => ({
  type: DataEntryActionTypes.MAKE_FILE_PRIVATE_ERROR,
  payload: error,
});

// rename document
export const startRenameDocument = (id, name, cb) => ({
  type: DataEntryActionTypes.RENAME_DOCUMENT_START,
  payload: { id, name, cb },
});

export const renameDocumentSuccess = () => ({
  type: DataEntryActionTypes.RENAME_DOCUMENT_SUCCESS,
});

export const renameDocumentFailure = (error) => ({
  type: DataEntryActionTypes.RENAME_DOCUMENT_ERROR,
  payload: error,
});

// delete document
export const startDeletingDocument = (id, cb) => ({
  type: DataEntryActionTypes.DELETE_DOCUMENT_START,
  payload: { id, cb },
});

export const deleteDocumentSuccess = () => ({
  type: DataEntryActionTypes.DELETE_DOCUMENT_SUCCESS,
});

export const deleteDocumentFailure = (error) => ({
  type: DataEntryActionTypes.DELETE_DOCUMENT_ERROR,
  payload: error,
});

// upload files
export const startManualUpload = (data, cb) => ({
  type: DataEntryActionTypes.MANUAL_UPLOAD_START,
  payload: { data, cb },
});

export const manualUploadSuccess = () => ({
  type: DataEntryActionTypes.MANUAL_UPLOAD_SUCCESS,
});

export const manualUploadFailure = (error) => ({
  type: DataEntryActionTypes.MANUAL_UPLOAD_ERROR,
  payload: error,
});

// download file
export const startFileDownload = (id, fileName) => ({
  type: DataEntryActionTypes.DOWNLOAD_FILE_START,
  payload: { id, fileName },
});

export const fileDownloadSuccess = () => ({
  type: DataEntryActionTypes.DOWNLOAD_FILE_SUCCESS,
});

export const fileDownloadFailure = (error) => ({
  type: DataEntryActionTypes.DOWNLOAD_FILE_ERROR,
  payload: error,
});
