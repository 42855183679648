import { combineReducers } from "redux";

import dataEntryReducer from "./document/reducer";
import userReducer from "./users/reducer";
import folderReducer from "./folder/reducer";
import projectReducer from "./project/reducer";
import requestReducer from "./request/reducer";
import searchReducer from "./search/reducer";

const rootReducer = combineReducers({
  dataEntry: dataEntryReducer,
  user: userReducer,
  folder: folderReducer,
  project: projectReducer,
  request: requestReducer,
  search: searchReducer,
});

export default rootReducer;
