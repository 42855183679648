import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal/modal";
import "./style.scss";
import { Box, Typography, Button, Alert } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LoopIcon from "@mui/icons-material/Loop";
import { deleteProjectStart } from "../../../../../redux/project/actions";
import { selectDeletingproject } from "../../../../../redux/project/selectors";

const ConfirmProjectDeleteModal = ({ open, closeModal, project, callBack }) => {
  const dispatch = useDispatch();
  const deleting = useSelector(selectDeletingproject);

  const handleDelete = () => {
    if (project) {
      dispatch(
        deleteProjectStart(project.id, () => {
          callBack && callBack();
          closeModal();
        })
      );
    }
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title="Confirm Document Deletion"
      modalFooter={
        <div className="modal_footer">
          <Button
            className="cancel me-2"
            onClick={closeModal}
            disabled={deleting}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            disabled={deleting}
            variant="contained"
            color="error"
            endIcon={
              deleting ? (
                <LoopIcon className="animate-spin" fontSize="medium" />
              ) : (
                <DeleteIcon fontSize="medium" />
              )
            }
          >
            {deleting ? "Deleting..." : "Delete"}
          </Button>
        </div>
      }
      style={{ maxWidth: "500px" }}
    >
      <Box>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Are you sure you want to delete this project?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <strong>{project?.name}</strong>
        </Typography>
        <Alert severity="warning">
          This action cannot be undone. Once deleted, all document associated with this project will be
          permanently removed from the system.
        </Alert>
      </Box>
    </Modal>
  );
};

export default ConfirmProjectDeleteModal;
