import { createSelector } from "reselect";

const UserSelector = (state) => state.user;

export const selectUsers = createSelector([UserSelector], (user) => user.users);

export const selectMembers = createSelector(
  [UserSelector],
  (user) => user.members
);

export const selectLoadingUsers = createSelector(
  [UserSelector],
  (user) => user.loadingUsers
);

export const selectLoadingMembers = createSelector(
  [UserSelector],
  (user) => user.loadingMembers
);

export const selectCreatingUser = createSelector(
  [UserSelector],
  (user) => user.creatingUser
);

export const selectUpdatingUser = createSelector(
  [UserSelector],
  (user) => user.updatingUser
);

export const selectActivatingUser = createSelector(
  [UserSelector],
  (user) => user.activatingUser
);

export const selectDeactivatingUser = createSelector(
  [UserSelector],
  (user) => user.deactivatingUser
);

export const selectResettingPassword = createSelector(
  [UserSelector],
  (user) => user.resettingPassword
);

export const selectDeletingUser = createSelector(
  [UserSelector],
  (user) => user.deletingUser
);
