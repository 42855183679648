import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../../../components/Modal/modal";
import "./style.scss";
import {
  TextField,
  Box,
  Alert,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { selectUpdatingproject } from "../../../../../redux/project/selectors";
import { updateProjectStart } from "../../../../../redux/project/actions";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

const UpdateProjectModal = ({ open, closeModal, title, callBack, project }) => {
  const dispatch = useDispatch();
  const updating = useSelector(selectUpdatingproject);

  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    if (project) {
      setProjectName(project.name || "");
      setDescription(project.description || "");
      setCategory(project.category);
    }
  }, [project]);

  const submitRequest = () => {
    if (projectName && description && category) {
      dispatch(
        updateProjectStart(
          project.id,
          projectName,
          description,
          category,
          () => {
            dispatch(callBack && callBack());
            reset();
          }
        )
      );
    }
  };

  const reset = () => {
    setProjectName("");
    setDescription("");
    setCategory("");
    closeModal();
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={title}
      modalFooter={
        <div className="modal_footer">
          <Button
            className="cancel me-2"
            onClick={reset}
            disabled={updating}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={submitRequest}
            disabled={!(projectName && description && category) || updating}
            variant="contained"
            endIcon={
              updating ? (
                <LoopIcon className="animate-spin" fontSize="medium" />
              ) : (
                <SaveRoundedIcon fontSize="medium" />
              )
            }
          >
            Save
          </Button>
        </div>
      }
      style={{ maxWidth: "600px" }}
    >
      <Box>
        <TextField
          label="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          fullWidth
          margin="normal"
        />

        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            label="Category"
          >
            <MenuItem value="SCIDAR">SCIDaR</MenuItem>
            <MenuItem value="SOLINA_HEALTH">SOLINA HEALTH</MenuItem>
          </Select>
        </FormControl>

        {(!projectName || !description || !category) && (
          <Alert severity="warning" sx={{ marginTop: 3 }}>
            Please fill out all fields before submitting.
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default UpdateProjectModal;
