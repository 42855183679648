import { put, takeEvery } from "redux-saga/effects";
import { fileDownloadSuccess, fileDownloadFailure } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import DataEntryActionTypes from "../types";
import { saveAs } from 'file-saver';

const { API_BASE_URL } = urls || {};

function* downloadFile({ payload: { id, fileName } }) {
  const link = `${API_BASE_URL}/documents/download/${id}`;


  const response = yield request({
    method: "get",
    url: link,
    responseType: 'blob'
  });
  if (response.success) {
    yield put(fileDownloadSuccess());
    saveAs(response.raw, fileName)
    notification.success({
      message: `Success`,
      description: "File downloaded successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(fileDownloadFailure("error"));
  }
}

export function* onFileDownload() {
  yield takeEvery(DataEntryActionTypes.DOWNLOAD_FILE_START, downloadFile);
}
