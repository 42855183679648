import React, { useState } from "react";
import "./index.scss";
import moment from "moment";
import {
  Avatar,
  AvatarGroup,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledMenu } from "../../../components/Menu/styled-menu";
import DeleteIcon from "@mui/icons-material/Delete";
import { Download, Edit, Send, Visibility } from "@mui/icons-material";
import { AvatarGroupComponent } from "../../../components/Avatar";
import { ManagersChipList } from "../../../components/Chip";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Divider from "@mui/material/Divider";

const ProjectRow = ({
  row,
  onEditClick,
  onDeleteClick,
  onProjectManagersClick,
  onProjectMembersClick,
}) => {
  // MENU FOR DOCUMENTS
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // END OF MENU

  return (
    <tr>
      <td>
        <div className="d-flex alig-items-center">
          <div className="info">
            <div>
              <Tooltip title={row.name}>
                <Typography
                  noWrap
                  variant="h4"
                  sx={{
                    maxWidth: 300,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {row.name}
                </Typography>
              </Tooltip>
              <span>
                {moment(row.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td>
        <ManagersChipList managers={row.managers} />
      </td>

      <td>
        <AvatarGroupComponent members={row.members} />
      </td>
      <td className="action">
        <Tooltip title="Manage">
          <IconButton onClick={handleClickMenu} className="no-style">
            <MoreVertIcon
              id="basic-button"
              aria-controls={open ? "manage-project-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
          </IconButton>
        </Tooltip>
        <StyledMenu
          id="manage-project-menu"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onProjectMembersClick(row);
            }}
          >
            <ListItemIcon>
              <PeopleRoundedIcon />
            </ListItemIcon>
            <ListItemText>Members</ListItemText>
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />

          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onProjectManagersClick(row);
            }}
          >
            <ListItemIcon>
              <PersonRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Manager</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onEditClick(row);
            }}
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem
            // disabled={!isDeletable(document)}
            onClick={() => {
              handleCloseMenu();
              onDeleteClick(row);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        </StyledMenu>
      </td>
    </tr>
  );
};

export default ProjectRow;
