import { createSelector } from "reselect";

const dataEntrySelector = (state) => state.dataEntry;

export const uploadingDataEntry = createSelector(
  [dataEntrySelector],
  (addons) => addons.uploading
);

export const selectRenamingDocument = createSelector(
  [dataEntrySelector],
  (addons) => addons.renaming
);

export const selectDeletingDocument = createSelector(
  [dataEntrySelector],
  (addons) => addons.deleting
);

export const loadingDataEntry = createSelector(
  [dataEntrySelector],
  (addons) => addons.loading
);

export const loadingMyUploadedFiles = createSelector(
  [dataEntrySelector],
  (addons) => addons.fetchingMyUploadedFiles
);

export const selectMyUploadedFiles = createSelector(
  [dataEntrySelector],
  (addons) => addons.myUploadedFiles
);

export const selectAllProjectsFiles = createSelector(
  [dataEntrySelector],
  (addons) => addons.allProjectsFiles
);

export const loadingAllProjectsFiles = createSelector(
  [dataEntrySelector],
  (addons) => addons.fetchingAllProjectsFiles
);

export const selectUploadingFile = createSelector(
  [dataEntrySelector],
  (addons) => addons.manuallyUploading
);

export const selectDownloadingFile = createSelector(
  [dataEntrySelector],
  (addons) => addons.downloadingFile
);

export const selectMakingFilePublic = createSelector(
  [dataEntrySelector],
  (addons) => addons.makingPublic
);

export const selectMakingFilePrivate = createSelector(
  [dataEntrySelector],
  (addons) => addons.makingPrivate
);
