import UserActionTypes from "./types";

// set any data
export const setAnyuserData = (payload) => ({
  type: UserActionTypes.SET_ANY_DATA,
  payload,
});

// fetch members
export const startFetchMembers = () => ({
  type: UserActionTypes.FETCH_MEMBERS_START,
});

export const fetchMembersSuccess = (members) => ({
  type: UserActionTypes.FETCH_MEMBERS_SUCCESS,
  payload: members,
});

export const fetchMembersFailure = (error) => ({
  type: UserActionTypes.FETCH_MEMBERS_ERROR,
  payload: error,
});

// fetch users
export const startFetchUsers = (
  page = 1,
  limit = 50,
  order = "asc",
  search,
  role
) => ({
  type: UserActionTypes.FETCH_USER_START,
  payload: { page, limit, order, search, role },
});

export const fetchUsersSuccess = (users) => ({
  type: UserActionTypes.FETCH_USER_SUCCESS,
  payload: users,
});

export const fetchUsersFailure = (error) => ({
  type: UserActionTypes.FETCH_USER_ERROR,
  payload: error,
});

// Activate user
export const startActivateUser = (id, cb) => ({
  type: UserActionTypes.ACTIVATE_USER_START,
  payload: { id, cb },
});

export const activateUserSuccess = () => ({
  type: UserActionTypes.ACTIVATE_USER_SUCCESS,
});

export const activateUserFailure = (error) => ({
  type: UserActionTypes.ACTIVATE_USER_ERROR,
  payload: error,
});

// Deactivate user
export const startDeactivateUser = (id, cb) => ({
  type: UserActionTypes.DEACTIVATE_USER_START,
  payload: { id, cb },
});

export const deactivateUserSuccess = () => ({
  type: UserActionTypes.DEACTIVATE_USER_SUCCESS,
});

export const deactivateUserFailure = (error) => ({
  type: UserActionTypes.DEACTIVATE_USER_ERROR,
  payload: error,
});

// Create user
export const createUserStart = (data, cb) => ({
  type: UserActionTypes.CREATE_USER_START,
  payload: { data, cb },
});

export const createUserSuccess = () => ({
  type: UserActionTypes.CREATE_USER_SUCCESS,
});

export const createUserFailure = (error) => ({
  type: UserActionTypes.CREATE_USER_ERROR,
  payload: error,
});

// Update user
export const updateUserStart = (
  id,
  firstName,
  lastName,
  email,
  phone,
  role,
  password,
  department,
  designation,
  cb
) => ({
  type: UserActionTypes.UPDATE_USER_START,
  payload: {
    id,
    firstName,
    lastName,
    email,
    phone,
    role,
    password,
    department,
    designation,
    cb,
  },
});

export const updateUserSuccess = () => ({
  type: UserActionTypes.UPDATE_USER_SUCCESS,
});

export const updateUserFailure = (error) => ({
  type: UserActionTypes.UPDATE_USER_ERROR,
  payload: error,
});

// Reset password
export const startResetPassword = (email, cb) => ({
  type: UserActionTypes.PASSWORD_RESET_START,
  payload: { email, cb },
});

export const resetPasswordSuccess = () => ({
  type: UserActionTypes.PASSWORD_RESET_SUCCESS,
});

export const resetPasswordFailure = (error) => ({
  type: UserActionTypes.PASSWORD_RESET_ERROR,
  payload: error,
});

// Delete user
export const startDeleteUser = (id, cb) => ({
  type: UserActionTypes.DELETE_USER_START,
  payload: { id, cb },
});

export const deleteUserSuccess = () => ({
  type: UserActionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFailure = (error) => ({
  type: UserActionTypes.DELETE_USER_ERROR,
  payload: error,
});
