import { put, takeEvery } from "redux-saga/effects";
import {
  makeDocumentPrivateFailure,
  makeDocumentPrivateSuccess,
} from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import DataEntryActionTypes from "../types";

const { API_BASE_URL } = urls || {};

function* makeDocumentPrivate({ payload: { id, cb } }) {
  const link = `${API_BASE_URL}/documents/${id}/private`;

  const response = yield request({
    method: "patch",
    url: link,
  });
  if (response.success) {
    yield put(makeDocumentPrivateSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "Document visibility changed successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description:
        response?.message?.message || "Something went wrong try again",
      placement: "topRight",
    });
    yield put(makeDocumentPrivateFailure("error"));
  }
}

export function* onMakeDocumentPrivate() {
  yield takeEvery(
    DataEntryActionTypes.MAKE_FILE_PRIVATE_START,
    makeDocumentPrivate
  );
}
