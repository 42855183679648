const SearchActionTypes = {
    SET_ANY_DATA: 'SET_ANY_DATA',
  
    FETCH_SEARCH_START: 'FETCH_SEARCH_START',
    FETCH_SEARCH_SUCCESS: 'FETCH_SEARCH_SUCCESS',
    FETCH_SEARCH_ERROR: 'FETCH_SEARCH_ERROR',

  };
  
  export default SearchActionTypes;
  