import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import "./index.scss";
import {
  Button,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  Typography,
  CardActions,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { EmptyState } from "../../../components/EmptyState";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  selectLoadingSearchResults,
  selectSearchResults,
} from "../../../redux/search/selectors";
import { startFetchSearchResults } from "../../../redux/search/actions";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import DocumentPreviewDialog from "../../../components/File-Viewer/documentPreviewDialog";
import { selectprojects } from "../../../redux/project/selectors";
import { startFetchProjects } from "../../../redux/project/actions";

const SearchOverview = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const searchResults = useSelector(selectSearchResults);
  const loading = useSelector(selectLoadingSearchResults);

  const projects = useSelector(selectprojects);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    dispatch(startFetchProjects());
  }, [dispatch]);

  const isPublic = false;

  const handleSearch = () => {
    dispatch(startFetchSearchResults(searchTerm, isPublic));
  };

  const onSearch = (val) => {
    setSearchTerm(val);
  };

  console.log(projects);

  const mapProject = (id) => {
    return projects.find((project) => project.id === id)?.name;
  };

  return (
    <Layout pageTitle="SCIDaR Data Retrieval Portal">
      <div className="mt-4">
        {!!preview && preview.path && (
          <DocumentPreviewDialog
            open={!!preview}
            handleClose={() => setPreview(null)}
            document={preview}
          />
        )}
        <div className="search_bar">
          <div className="d-flex search-inputs">
            <TextField
              id="search-input"
              label="Search documents by file name or content"
              variant="outlined"
              value={searchTerm}
              onChange={({ target: { value } }) => onSearch(value)}
            />
          </div>
          <Button
            className="search tw-ml-4"
            variant="contained"
            endIcon={<SearchRoundedIcon />}
            disabled={searchTerm === "" || loading}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>

        {loading ? (
          <Loader />
        ) : searchResults?.data?.length < 1 ? (
          <EmptyState title={"No Results Found"} disabled={loading} />
        ) : (
          <div className="search_results_container mt-4">
            {searchResults &&
              searchResults?.data &&
              searchResults?.data.map(({ _source: result }, i) => (
                <Card sx={{ minWidth: 275, marginBottom: 2 }} key={result.id}>
                  <CardContent>
                    <Typography
                      gutterBottom
                      sx={{ color: "text.secondary", fontSize: 14 }}
                    >
                      {moment(result.uploadDate).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </Typography>
                    <Typography variant="h5" component="div">
                      {result.originalFilename}
                    </Typography>
                    <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
                      {result.projectIDs?.map((id)=>mapProject(id)).join(", ")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                      }}
                    >
                      <ReactMarkdown>{result.content}</ReactMarkdown>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() =>
                        setPreview({
                          path: result.path,
                          type: result.type,
                        })
                      }
                    >
                      Preview
                    </Button>
                  </CardActions>
                </Card>
              ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SearchOverview;
