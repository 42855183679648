import React, { useState } from "react";
import "./index.scss";
import moment from "moment";
import { convertBytes } from "../../../helpers";
import {
  checkFileFormat,
  removeFileExtension,
} from "../../../helpers/fileProcessing";
import {
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledMenu } from "../../../components/Menu/styled-menu";
import DeleteIcon from "@mui/icons-material/Delete";
import { Download, Visibility } from "@mui/icons-material";
import PublicOffRoundedIcon from "@mui/icons-material/PublicOffRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import { Edit } from "@mui/icons-material";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const userType = sessionStorage.getItem("user_type");

const AuditDocumentRow = ({
  row,
  onDeleteClick,
  onApproveClick,
  onDeclineClick,
  onRenameClick,
  onPreviewClick,
  onDownloadClick,
}) => {
  // MENU FOR DOCUMENTS
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const canDelete = userType?.toLowerCase() === "system_admin";
  const canRename = userType?.toLowerCase() === "system_admin";
  const canSubmit = userType?.toLowerCase() === "system_admin";
  const canMakePublic =
    userType?.toLowerCase() === "system_admin" &&
    row?.document.visibility === "PRIVATE";
  const canMakePrivate =
    userType?.toLowerCase() === "system_admin" &&
    row?.document.visibility === "PUBLIC";

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // END OF MENU

  return (
    <tr>
      <td>
        <div className="d-flex alig-items-center">
          <div className="info">
            <img src={checkFileFormat(row?.document.contentType)} alt="" />
            <div>
              <Tooltip title={row?.document.originalFilename}>
                <Typography
                  noWrap
                  variant="h4"
                  sx={{
                    maxWidth: 300,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {removeFileExtension(row?.document.originalFilename)}
                </Typography>
              </Tooltip>
              <span>
                {moment(row?.document.createdAt).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td style={{ textTransform: "capitalize" }}>
        {!!row?.submittedBy?.firstName || !!row?.submittedBy?.lastName
          ? `${row?.submittedBy?.firstName} ${row?.submittedBy?.lastName}`
          : "N/A"}
      </td>
      <td>{convertBytes(row?.document.size)}</td>
      <td>
        <Chip label={row?.project?.name} variant="filled" size="medium" />
      </td>

      <td className="action">
        <Tooltip title="Manage">
          <IconButton onClick={handleClickMenu} className="no-style">
            <MoreVertIcon
              id="basic-button"
              aria-controls={open ? "manage-document-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
          </IconButton>
        </Tooltip>
        <StyledMenu
          id="manage-document-menu"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onPreviewClick(row);
            }}
          >
            <ListItemIcon>
              <Visibility fontSize="small" />
            </ListItemIcon>
            <ListItemText>Preview</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onDownloadClick(row.document);
            }}
          >
            <ListItemIcon>
              <Download fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
          </MenuItem>

          {
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onApproveClick(row);
              }}
            >
              <ListItemIcon>
                <DoneOutlineRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Approve</ListItemText>
            </MenuItem>
          }

          {
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onDeclineClick(row);
              }}
            >
              <ListItemIcon>
                <CloseRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Decline</ListItemText>
            </MenuItem>
          }

          <Divider sx={{ my: 0 }} />

          {
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onRenameClick(row);
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Rename</ListItemText>
            </MenuItem>
          }

          {
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onDeleteClick(row);
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          }
        </StyledMenu>
      </td>
    </tr>
  );
};

export default AuditDocumentRow;
