import { put, takeEvery } from "redux-saga/effects";
import { fetchRequestsSuccess, fetchRequestsFailure } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import RequestActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* fetchAllRequests(action) {
  const { page, limit, order, search } = action?.payload;

  const queryParams = {
    page,
    limit,
    order,
    status: "PENDING",
  };

  // Conditionally add `search` to the query parameters
  if (search && search !== "") {
    queryParams["search"] = search;
  }

  const queryString = new URLSearchParams(queryParams).toString();

  const url = `${API_BASE_URL}/approval-requests?${queryString}`;

  const response = yield request({
    method: "get",
    url,
  });
  if (response.success) {
    yield put(fetchRequestsSuccess(response?.raw));
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(fetchRequestsFailure("error"));
  }
}

export function* getAllRequests() {
  yield takeEvery(RequestActionTypes.FETCH_REQUESTS_START, fetchAllRequests);
}
