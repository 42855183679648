const FolderActionTypes = {
    SET_ANY_DATA: 'SET_ANY_DATA',
  
    FETCH_FOLDERS_START: 'FETCH_FOLDERS_START',
    FETCH_FOLDERS_SUCCESS: 'FETCH_FOLDERS_SUCCESS',
    FETCH_FOLDERS_ERROR: 'FETCH_FOLDERS_ERROR',

    CREATE_FOLDER_START: 'CREATE_FOLDER_START',
    CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
    CREATE_FOLDER_ERROR: 'CREATE_FOLDER_ERROR',

    UPDATE_FOLDER_START: 'UPDATE_FOLDER_START',
    UPDATE_FOLDER_SUCCESS: 'UPDATE_FOLDER_SUCCESS',
    UPDATE_FOLDER_ERROR: 'UPDATE_FOLDER_ERROR',

    DELETE_FOLDER_START: 'DELETE_FOLDER_START',
    DELETE_FOLDER_SUCCESS: 'DELETE_FOLDER_SUCCESS',
    DELETE_FOLDER_ERROR: 'DELETE_FOLDER_ERROR',
  };
  
  export default FolderActionTypes;
  