const DataEntryActionTypes = {
  SET_ANY_DATA: 'SET_ANY_DATA',

  FETCH_MY_UPLOADS_START: 'FETCH_MY_UPLOADS_START',
  FETCH_MY_UPLOADS_SUCCESS: 'FETCH_MY_UPLOADS_SUCCESS',
  FETCH_MY_UPLOADS_ERROR: 'FETCH_MY_UPLOADS_ERROR',

  FETCH_ALL_PROJECTS_FILES_START: 'FETCH_ALL_PROJECTS_FILES_START',
  FETCH_ALL_PROJECTS_FILES_SUCCESS: 'FETCH_ALL_PROJECTS_FILES_SUCCESS',
  FETCH_ALL_PROJECTS_FILES_ERROR: 'FETCH_ALL_PROJECTS_FILES_ERROR',

  MANUAL_UPLOAD_START: 'MANUAL_UPLOAD_START',
  MANUAL_UPLOAD_SUCCESS: 'MANUAL_UPLOAD_SUCCESS',
  MANUAL_UPLOAD_ERROR: 'MANUAL_UPLOAD_ERROR',

  RENAME_DOCUMENT_START: 'RENAME_DOCUMENT_START',
  RENAME_DOCUMENT_SUCCESS: 'RENAME_DOCUMENT_SUCCESS',
  RENAME_DOCUMENT_ERROR: 'RENAME_DOCUMENT_ERROR',

  DELETE_DOCUMENT_START: 'DELETE_DOCUMENT_START',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_ERROR: 'DELETE_DOCUMENT_ERROR',

  DOWNLOAD_FILE_START: 'DOWNLOAD_FILE_START',
  DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_ERROR: 'DOWNLOAD_FILE_ERROR',

  MAKE_FILE_PUBLIC_START: 'MAKE_FILE_PUBLIC_START',
  MAKE_FILE_PUBLIC_SUCCESS: 'MAKE_FILE_PUBLIC_SUCCESS',
  MAKE_FILE_PUBLIC_ERROR: 'MAKE_FILE_PUBLIC_ERROR',

  MAKE_FILE_PRIVATE_START: 'MAKE_FILE_PRIVATE_START',
  MAKE_FILE_PRIVATE_SUCCESS: 'MAKE_FILE_PRIVATE_SUCCESS',
  MAKE_FILE_PRIVATE_ERROR: 'MAKE_FILE_PRIVATE_ERROR',
};

export default DataEntryActionTypes;
