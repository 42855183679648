import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal/modal";
import "./style.scss";
import { selectRenamingDocument } from "../../../../../redux/document/selectors";
import { TextField, Box, Typography, Button, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LoopIcon from "@mui/icons-material/Loop";
import {
  fetchMyUploadedFiles,
  startRenameDocument,
} from "../../../../../redux/document/actions";

const RenameDocumentModal = ({ open, closeModal, title, document }) => {
  const dispatch = useDispatch();
  const renaming = useSelector(selectRenamingDocument);
  const [newName, setNewName] = useState("");

  useEffect(() => {
    if (document) {
      setNewName(document.originalFilename || "");
    }
  }, [document]);

  const handleRename = () => {
    if (document && newName.trim()) {
      dispatch(
        startRenameDocument(document.id, newName.trim(), () => {
          dispatch(fetchMyUploadedFiles());
          reset();
        })
      );
    }
  };

  const reset = () => {
    setNewName("");
    closeModal();
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={title}
      modalFooter={
        <div className="modal_footer">
          <Button
            className="cancel me-2"
            onClick={reset}
            disabled={renaming}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleRename}
            disabled={
              !newName.trim() ||
              renaming ||
              newName === document?.originalFilename
            }
            variant="contained"
            endIcon={
              renaming ? (
                <LoopIcon className="animate-spin" fontSize="medium" />
              ) : (
                <EditIcon fontSize="medium" />
              )
            }
          >
            Rename
          </Button>
        </div>
      }
      style={{ maxWidth: "500px" }} // Adjust size here
    >
      <Box>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Current Name: {document?.originalFilename || "No document selected"}
        </Typography>

        <TextField
          fullWidth
          label="New Document Name"
          variant="outlined"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        {!newName.trim() && (
          <Alert severity="warning">
            Please enter a new name for the document.
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default RenameDocumentModal;
