import { put, takeEvery } from "redux-saga/effects";
import { fetchProjectsSuccess, fetchProjectsFailure } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import ProjectActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* fetchAllProjects(action) {
  const { page, limit, order, search, category } = action?.payload;

  const queryParams = {
    page,
    limit,
    order,
  };

  // Conditionally add `category` to the query parameters
  if (category && category !== "") {
    queryParams["category"] = category;
  }

  if (search) {
    queryParams["search"] = search;
  }

  const queryString = new URLSearchParams(queryParams).toString();

  const url = `${API_BASE_URL}/projects?${queryString}`;

  const response = yield request({
    method: "get",
    url,
  });
  if (response.success) {
    yield put(fetchProjectsSuccess(response?.raw));
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(fetchProjectsFailure("error"));
  }
}

export function* onFetchAllProjects() {
  yield takeEvery(ProjectActionTypes.FETCH_PROJECTS_START, fetchAllProjects);
}
