import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import "./index.scss";
import { Input, Select as AntdSelect } from "antd";
import Pagination from "@mui/material/Pagination";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Button,
} from "@mui/material";
import moment from "moment";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import { startFileDownload } from "../../../redux/document/actions";
import MyDocumentRow from "./UserRow";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { EmptyState } from "../../../components/EmptyState";
import {
  selectActivatingUser,
  selectDeactivatingUser,
  selectDeletingUser,
  selectLoadingUsers,
  selectResettingPassword,
  selectUsers,
} from "../../../redux/users/selectors";
import {
  startActivateUser,
  startDeactivateUser,
  startFetchUsers,
  startResetPassword,
} from "../../../redux/users/actions";
import { downloadFile, formatRcDate } from "../../../helpers";
import UserRow from "./UserRow";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import ConfirmUserDeleteModal from "./modals/ConfirmUserDeleteModal";
import CreateUserModal from "./modals/CreateUserModal";

const UserManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = sessionStorage.getItem("user_type");

  useEffect(() => {
    if (userType?.toLowerCase() !== "system_admin") {
      navigate("/overview");
    }
  }, [navigate, userType]);

  const [createUserDialog, setCreateUserDialog] = useState(null);
  const [updateUserDialog, setUpdateUserDialog] = useState(null);
  const [deleteUserDialog, setDeleteUserDialog] = useState(null);

  const [filterOn, setFilterOn] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [role, setRole] = useState(null);

  const users = useSelector(selectUsers);
  const loadingUsers = useSelector(selectLoadingUsers);
  const activatingUser = useSelector(selectActivatingUser);
  const deactivatingUser = useSelector(selectDeactivatingUser);
  const resettingPassword = useSelector(selectResettingPassword);
  const deletingUser = useSelector(selectDeletingUser);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("desc");

  const loading =
    loadingUsers ||
    activatingUser ||
    deactivatingUser ||
    resettingPassword ||
    deletingUser;

  const callBack = () => {
    dispatch(startFetchUsers(page, limit, order, searchTerm, role));
  };

  useEffect(() => {
    callBack();
  }, [dispatch, page, limit, order, searchTerm, role]);

  const onSearch = (val) => {
    if (val) {
      setFilterOn(true);
    } else {
      setFilterOn(false);
    }
    setSearchTerm(val);
  };

  const handleActivateUser = (user) => {
    dispatch(
      startActivateUser(user.id, () => {
        callBack();
      })
    );
  };

  const handleResetPassword = (user) => {
    dispatch(
      startResetPassword(user.email, () => {
        callBack();
      })
    );
  };

  const handleDeactivateUser = (user) => {
    dispatch(
      startDeactivateUser(user.id, () => {
        callBack();
      })
    );
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1); // Reset to first page when limit changes
  };

  function exportUsers() {
    const csv = Papa.unparse(
      users?.data.map((user) => ({
        "Full Name": `${user.firstName} ${user.lastName}`,
        Email: user.email,
        "Phone Number": user.phone,
        Roles: (user?.roles || [])
          .map((role) => role.replaceAll("_", " "))
          .join(", "),
        "Date Created": moment(user.createdAt).format("MMMM Do YYYY"),
      }))
    );
    downloadFile(csv, `users-list-${formatRcDate(new Date())}`);
  }

  return (
    <Layout pageTitle="User Management">
      <div className="mt-4">
        <CreateUserModal
          title={"Create User"}
          open={!!createUserDialog}
          closeModal={() => setCreateUserDialog(null)}
          callBack={() => {
            callBack();
            setCreateUserDialog(null);
          }}
        />
        <ConfirmUserDeleteModal
          title={"Delete User"}
          open={!!deleteUserDialog}
          closeModal={() => setDeleteUserDialog(null)}
          user={deleteUserDialog}
          callBack={() => callBack()}
        />
        <div className="filter_bar">
          <div className="d-flex filter-inputs">
            <Input
              value={searchTerm}
              onChange={({ target: { value } }) => onSearch(value)}
              placeholder="Search users"
            />
            <AntdSelect
              allowClear
              style={{
                width: "40%",
              }}
              onChange={(e) => {
                setRole(e ? e : null);
                setFilterOn(e ? true : false);
              }}
              placeholder="Filter by Role"
            >
              <AntdSelect.Option value="NEW_STAFF">NEW_STAFF</AntdSelect.Option>
              <AntdSelect.Option value="PROGRAM_OPERATION_STAFF">
                PROGRAM OPERATION STAFF
              </AntdSelect.Option>
              <AntdSelect.Option value="MANAGEMENT_STAFF">
                MANAGEMENT STAFF
              </AntdSelect.Option>
              <AntdSelect.Option value="SYSTEM_ADMIN">
                SYSTEM ADMIN
              </AntdSelect.Option>
            </AntdSelect>
          </div>

          <div className="d-flex align-items-center">
            <Button
              onClick={() => setCreateUserDialog(true)}
              disabled={loading}
              variant="contained"
              endIcon={<AddRoundedIcon />}
              sx={{
                textTransform: "none",
                mr: 2,
              }}
            >
              Create
            </Button>
            <Button
              onClick={() => exportUsers()}
              disabled={loading}
              variant="contained"
              endIcon={<DownloadRoundedIcon />}
              sx={{
                textTransform: "none",
              }}
            >
              Export
            </Button>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : users?.data?.length < 1 ? (
          <EmptyState title={"No Users Found"} disabled={loading} />
        ) : (
          <div className="table_container mt-4">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Date Created</th>
                  <th>Role</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users?.data &&
                  users?.data.map((data, i) => (
                    <UserRow
                      key={data.id}
                      row={data}
                      onEditClick={(user) => setUpdateUserDialog(user)}
                      onDeleteClick={(user) => setDeleteUserDialog(user)}
                      onActivateClick={(user) => handleActivateUser(user)}
                      onDeactivateClick={(user) => handleDeactivateUser(user)}
                      onResetPasswordClick={(user) => handleResetPassword(user)}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && users?.data?.length ? (
          <div className="pagination mt-4">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Pagination
                variant="outlined"
                count={users?.meta?.lastPage || 0}
                page={page}
                onChange={handlePageChange}
                color="primary"
                shape="rounded"
              />
              <FormControl
                variant="outlined"
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <InputLabel>Items per page</InputLabel>
                <Select
                  value={limit}
                  onChange={handleLimitChange}
                  label="Items per page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default UserManagement;
