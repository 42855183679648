import { call, all } from "redux-saga/effects";
import { onFetchMyUploadedFiles } from "./fetchMyUploadedFiles";
import { onUploadFile } from "./uploadFile";
import { onFileDownload } from "./fileDownload";
import { onRenameDocument } from "./renameDocument";
import { onDeleteDocument } from "./deleteDocument";
import { onFetchAllProjectsFiles } from "./fetchAllProjectsFiles";
import { onMakeDocumentPublic } from "./makeDocumentPublic";
import { onMakeDocumentPrivate } from "./makeDocumentPrivate";

export default function* addonsSaga() {
  yield all([
    call(onFetchMyUploadedFiles),
    call(onFetchAllProjectsFiles),
    call(onUploadFile),
    call(onFileDownload),
    call(onRenameDocument),
    call(onDeleteDocument),
    call(onMakeDocumentPublic),
    call(onMakeDocumentPrivate),
  ]);
}
