import { Button, Typography } from "@mui/material";
import Empty from "../../assets/images/svg/empty_state.svg";
import "./empty-state.scss";

export const EmptyState = ({
  img,
  action,
  title,
  buttonText,
  buttonIcon,
  disabled,
}) => {
  return (
    <div
      className="empty_state_container empty_state"
      style={{
        padding: 40,
      }}
    >
      <img src={img || Empty} alt="" />
      <Typography sx={{ color: "text.secondary", mb: 1.5, mt: 3 }}>
        {title || "No records found"}
      </Typography>
      {buttonText && (
        <Button
          onClick={action}
          disabled={disabled}
          variant="contained"
          endIcon={buttonIcon}
          sx={{
            textTransform: "none",
          }}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};
