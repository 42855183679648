import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal/modal";
import "./style.scss";
import { Box, Typography, Button, Alert } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import {
  fetchAllProjectsFiles,
  startMakingDocumentPrivate,
} from "../../../../../redux/document/actions";
import PublicOffRoundedIcon from "@mui/icons-material/PublicOffRounded";
import { selectMakingFilePrivate } from "../../../../../redux/document/selectors";

const ConfirmDocumentPrivateModal = ({ open, closeModal, document }) => {
  const dispatch = useDispatch();
  const makingPrivate = useSelector(selectMakingFilePrivate);

  const handleMakePrivate = () => {
    if (document) {
      dispatch(
        startMakingDocumentPrivate(document.id, () => {
          dispatch(fetchAllProjectsFiles());
          closeModal();
        })
      );
    }
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title="Confirm Document Visibility"
      modalFooter={
        <div className="modal_footer">
          <Button
            className="cancel me-2"
            onClick={closeModal}
            disabled={makingPrivate}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleMakePrivate}
            disabled={makingPrivate}
            variant="contained"
            color="error"
            endIcon={
              makingPrivate ? (
                <LoopIcon className="animate-spin" fontSize="medium" />
              ) : (
                <PublicOffRoundedIcon fontSize="medium" />
              )
            }
          >
            {makingPrivate ? "Saving..." : "Save"}
          </Button>
        </div>
      }
      style={{ maxWidth: "500px" }}
    >
      <Box>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Are you sure you want to make this document private?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <strong>{document?.originalFilename}</strong>
        </Typography>
        <Alert severity="warning">
          This action should not be performed without approval. Once private,
          the document will only be accessible to logged in users.
        </Alert>
      </Box>
    </Modal>
  );
};

export default ConfirmDocumentPrivateModal;
