import { call, all } from 'redux-saga/effects';
import { onCreateProject } from './createProject';
import { onUpdateProject } from './updateProject';
import { onDeleteProject } from './deleteProject';
import { onUpdateProjectMembers } from './updateProjectMembers';
import { onFetchAllProjects } from './fetchProjects';
import { onUpdateProjectManagers } from './updateProjectManagers';

export default function* projectSaga() {
  yield all([
    call(onFetchAllProjects),
    call(onCreateProject),
    call(onUpdateProject),
    call(onDeleteProject),
    call(onUpdateProjectMembers),
    call(onUpdateProjectManagers),
  ]);
}
