import RequestActionTypes from "./types";

const initialState = {
  requests: [],
  loadingRequests: false,
  creatingRequest: false,
  deletingRequest: false,
  approvingRequest: false,
  decliningRequest: false,
  error: null,
};

function requestReducer(state = initialState, { type, payload }) {
  switch (type) {
    case RequestActionTypes.SET_ANY_DATA:
      return {
        ...state,
        ...payload,
      };
    case RequestActionTypes.FETCH_REQUESTS_START:
      return {
        ...state,
        loadingRequests: true,
      };
    case RequestActionTypes.FETCH_REQUESTS_SUCCESS:
      return {
        ...state,
        loadingRequests: false,
        requests: payload,
      };
    case RequestActionTypes.FETCH_REQUESTS_ERROR:
      return {
        ...state,
        loadingRequests: false,
        error: payload,
      };

    case RequestActionTypes.CREATE_REQUEST_START:
      return {
        ...state,
        creatingRequest: true,
      };
    case RequestActionTypes.CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        creatingRequest: false,
      };
    case RequestActionTypes.CREATE_REQUEST_ERROR:
      return {
        ...state,
        creatingRequest: false,
        error: payload,
      };

    case RequestActionTypes.APPROVE_REQUEST_START:
      return {
        ...state,
        approvingRequest: true,
      };
    case RequestActionTypes.APPROVE_REQUEST_SUCCESS:
      return {
        ...state,
        approvingRequest: false,
      };
    case RequestActionTypes.APPROVE_REQUEST_ERROR:
      return {
        ...state,
        approvingRequest: false,
        error: payload,
      };

    case RequestActionTypes.DECLINE_REQUEST_START:
      return {
        ...state,
        decliningRequest: true,
      };
    case RequestActionTypes.DECLINE_REQUEST_SUCCESS:
      return {
        ...state,
        decliningRequest: false,
      };
    case RequestActionTypes.DECLINE_REQUEST_ERROR:
      return {
        ...state,
        decliningRequest: false,
        error: payload,
      };

    case RequestActionTypes.DELETE_REQUEST_START:
      return {
        ...state,
        deletingRequest: true,
      };
    case RequestActionTypes.DELETE_REQUEST_SUCCESS:
      return {
        ...state,
        deletingRequest: false,
      };
    case RequestActionTypes.DELETE_REQUEST_ERROR:
      return {
        ...state,
        deletingRequest: false,
        error: payload,
      };
    default:
      return state;
  }
}

export default requestReducer;
