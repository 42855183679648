import { put, takeEvery } from "redux-saga/effects";
import { fetchMembersSuccess, fetchMembersFailure } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import UserActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* fetchAllMembers() {
  const url = `${API_BASE_URL}/users/members`;

  const response = yield request({
    method: "get",
    url,
  });
  if (response.success) {
    yield put(fetchMembersSuccess(response?.raw?.data));
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(fetchMembersFailure("error"));
  }
}

export function* onGetAllMembers() {
  yield takeEvery(UserActionTypes.FETCH_MEMBERS_START, fetchAllMembers);
}
