import { put, takeEvery } from "redux-saga/effects";
import {
  fetchAllProjectsFilesSuccess,
  fetchAllProjectsFilesFailure,
} from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import DataEntryActionTypes from "../types";

const { API_BASE_URL } = urls || {};

// fetch uploaded manual files
function* fetchAllProjectsFiles(action) {
  const { page, limit, order, search, projects } = action.payload;

  const queryParams = {
    page,
    limit,
    order,
    search,
  };

  // Conditionally add `projectIDs[]` to the query parameters
  if (projects && projects.length > 0) {
    queryParams["projectIDs[]"] = projects;
  }

  const queryString = new URLSearchParams(queryParams).toString();

  const link = `${API_BASE_URL}/documents?${queryString}`;
  const response = yield request({
    method: "get",
    url: link,
  });
  if (response.success) {
    yield put(fetchAllProjectsFilesSuccess(response?.raw));
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(fetchAllProjectsFilesFailure("error"));
  }
}

export function* onFetchAllProjectsFiles() {
  yield takeEvery(
    DataEntryActionTypes.FETCH_ALL_PROJECTS_FILES_START,
    fetchAllProjectsFiles
  );
}
