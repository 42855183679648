import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import "./index.scss";
import { Input, Select as AntdSelect } from "antd";
import Pagination from "@mui/material/Pagination";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Button,
} from "@mui/material";
import ManualuploadModal from "./modals/ManualUploadModal";
import { useSelector, useDispatch } from "react-redux";
import {
  loadingMyUploadedFiles,
  selectMyUploadedFiles,
} from "../../../redux/document/selectors";
import { selectprojects } from "../../../redux/project/selectors";
import Empty from "../../../assets/images/svg/empty_state.svg";
import Loader from "../../../components/Loader";
import {
  fetchMyUploadedFiles,
  startFileDownload,
} from "../../../redux/document/actions";
import { startFetchProjects } from "../../../redux/project/actions";
import DocumentPreviewDialog from "../../../components/File-Viewer/documentPreviewDialog";
import RequestApprovalModal from "./modals/RequestApprovalModal";
import RenameDocumentModal from "./modals/RenameDocumentModal";
import MyDocumentRow from "./MyDocumentRow";
import ConfirmDocumentDeleteModal from "./modals/ConfirmDocumentDeleteModal";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { EmptyState } from "../../../components/EmptyState";

const DataEntry = () => {
  const dispatch = useDispatch();
  const projects = useSelector(selectprojects);
  const [manualUpload, setManualUpload] = useState(false);
  const [requestApprovalDocument, setRequestApprovalDocument] = useState(null);
  const [renameMyDocument, setRenameMyDocument] = useState(null);
  const [deleteMyDocument, setDeleteMyDocument] = useState(null);
  const [filterOn, setFilterOn] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [preview, setPreview] = useState(null);
  const [project, setProject] = useState([]);
  const manualFiles = useSelector(selectMyUploadedFiles);
  const loading = useSelector(loadingMyUploadedFiles);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("desc");

  useEffect(() => {
    dispatch(fetchMyUploadedFiles(page, limit, order, searchTerm, project));
    dispatch(startFetchProjects());
  }, [dispatch, page, limit, order, searchTerm, project]);

  const onSearch = (val) => {
    if (val) {
      setFilterOn(true);
    } else {
      setFilterOn(false);
    }
    setSearchTerm(val);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1); // Reset to first page when limit changes
  };

  const downloadFile = async (data) => {
    dispatch(startFileDownload(data.id, data.originalFilename));
  };

  return (
    <Layout pageTitle="Data Entry">
      <div className="mt-4">
        <ConfirmDocumentDeleteModal
          open={!!deleteMyDocument}
          document={deleteMyDocument}
          closeModal={() => setDeleteMyDocument(null)}
        />
        <RequestApprovalModal
          title="Request Approval"
          open={!!requestApprovalDocument}
          document={requestApprovalDocument}
          closeModal={() => setRequestApprovalDocument(null)}
        />
        <RenameDocumentModal
          title="Rename Document"
          open={!!renameMyDocument}
          document={renameMyDocument}
          closeModal={() => setRenameMyDocument(null)}
        />
        <ManualuploadModal
          title="Manual File Upload"
          open={manualUpload}
          closeModal={() => setManualUpload(false)}
        />
        {!!preview && preview.path && (
          <DocumentPreviewDialog
            open={!!preview}
            handleClose={() => setPreview(null)}
            document={preview}
          />
        )}
        {
          <div className="filter_bar">
            <div className="d-flex filter-inputs">
              <Input
                value={searchTerm}
                onChange={({ target: { value } }) => onSearch(value)}
                placeholder="Search documents by file name"
              />
              <AntdSelect
                allowClear
                style={{
                  width: "40%",
                }}
                onChange={(e) => {
                  setProject(e ? [e] : null);
                  setFilterOn(e ? true : false);
                }}
                placeholder="Filter by Project"
              >
                {projects.data &&
                  (projects?.data || []).map((project, i) => (
                    <AntdSelect.Option key={i} value={project?.id}>
                      {project?.name.toUpperCase()}
                    </AntdSelect.Option>
                  ))}
              </AntdSelect>
            </div>

            {!loading && manualFiles?.data?.length > 0 && <Button
              onClick={() => setManualUpload(true)}
              disabled={loading}
              variant="contained"
              endIcon={<FileUploadOutlinedIcon />}
              sx={{
                textTransform: "none",
              }}
            >
              Upload
            </Button>}
          </div>
        }
        {loading ? (
          <Loader />
        ) : manualFiles?.data?.length < 1 ? (
          <EmptyState
            title={"No File Found"}
            action={() => setManualUpload(true)}
            disabled={loading}
            buttonIcon={<FileUploadOutlinedIcon />}
            buttonText={"Upload"}
          />
        ) : (
          <div className="table_container mt-4">
            <table>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>File Size</th>
                  <th>Projects</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {manualFiles &&
                  manualFiles?.data &&
                  manualFiles?.data.map((data, i) => (
                    <MyDocumentRow
                      key={data.id}
                      row={data}
                      onEditClick={(doc) => setRenameMyDocument(doc)}
                      onDeleteClick={(doc) => setDeleteMyDocument(doc)}
                      onRequestApprovalClick={(doc) =>
                        setRequestApprovalDocument(doc)
                      }
                      onPreviewClick={(doc) =>
                        setPreview({
                          path: doc.path,
                          type: doc.type,
                        })
                      }
                      onDownloadClick={downloadFile}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && manualFiles?.data?.length ? (
          <div className="pagination mt-4">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Pagination
                variant="outlined"
                count={manualFiles?.meta?.lastPage || 0}
                page={page}
                onChange={handlePageChange}
                color="primary"
                shape="rounded"
              />
              <FormControl
                variant="outlined"
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <InputLabel>Items per page</InputLabel>
                <Select
                  value={limit}
                  onChange={handleLimitChange}
                  label="Items per page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default DataEntry;
