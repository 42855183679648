import React from "react";
import "./style.scss";

const Modal = ({ open, className, title, children, modalFooter }) => {
  return (
    <div className={`modal_container ${open ? "show" : ""} ${className}`}>
      <div className="modal_box">
        <div className="modal_content">
          <div className="modal_header">
            <h3>{title}</h3>
          </div>
          <div className="modal_body">{children}</div>
          {modalFooter}
        </div>
      </div>
    </div>
  );
};

export default Modal;
