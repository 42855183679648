import { getAllFolders } from './fetchFolders';
import { call, all } from 'redux-saga/effects';
import { onCreateFolder } from './createFolder';
import { onUpdateFolder } from './updateFolder';
import { onDeleteFolder } from './deleteFolder';

export default function* folderSaga() {
  yield all([
    call(getAllFolders),
    call(onCreateFolder),
    call(onUpdateFolder),
    call(onDeleteFolder)
  ]);
}
