import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Autocomplete,
  TextField,
  Chip,
  Box,
  Alert,
  Button,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import Modal from "../../../../../components/Modal/modal";
import { selectUpdatingproject } from "../../../../../redux/project/selectors";
import {
  updateProjectMembersStart,
  updateProjectStart,
} from "../../../../../redux/project/actions";
import {
  selectLoadingMembers,
  selectMembers,
} from "../../../../../redux/users/selectors";
import { startFetchMembers } from "../../../../../redux/users/actions";

const ProjectMembersModal = ({
  open,
  closeModal,
  title,
  callBack,
  project,
}) => {
  const dispatch = useDispatch();
  const updating = useSelector(selectUpdatingproject);
  const loading = useSelector(selectLoadingMembers);
  const allMembers = useSelector(selectMembers);

  const [members, setMembers] = useState([]);

  useEffect(() => {
    dispatch(startFetchMembers());
    if (project) {
      setMembers(project.members || []);
    }
  }, [dispatch, project]);

  const handleSubmit = () => {
    dispatch(
      updateProjectMembersStart(
        project.id,
        members.map((m) => m.id), // Assuming `id` is the unique identifier for a member
        () => {
          callBack && callBack();
          reset();
        }
      )
    );
  };

  const reset = () => {
    setMembers([]);
    closeModal();
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={title}
      modalFooter={
        <div className="modal_footer">
          <Button
            className="cancel me-2"
            onClick={reset}
            disabled={updating}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            disabled={!members.length || updating}
            variant="contained"
            endIcon={
              updating ? (
                <LoopIcon className="animate-spin" fontSize="medium" />
              ) : (
                <SaveRoundedIcon fontSize="medium" />
              )
            }
          >
            Save
          </Button>
        </div>
      }
      style={{ maxWidth: "600px" }}
    >
      <Box>
        <Autocomplete
          multiple
          options={allMembers} // The list of all possible members
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          value={members}
          onChange={(event, newValue) => setMembers(newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={`${option.firstName} ${option.lastName}`}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Project Members"
              placeholder="Add members"
              fullWidth
              margin="normal"
            />
          )}
        />

        {!members.length && (
          <Alert severity="warning" sx={{ marginTop: 3 }}>
            Please fill out all fields and select at least one member before
            submitting.
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default ProjectMembersModal;
