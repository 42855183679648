import { put, takeEvery } from "redux-saga/effects";
import { manualUploadSuccess, manualUploadFailure } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import DataEntryActionTypes from "../types";

const { API_BASE_URL } = urls || {};

function* uploadFile({ payload: { data, cb } }) {
  const { file, tags } = data;
  const link = `${API_BASE_URL}/documents/upload`;

  let formData = new FormData();

  for (let i = 0; i < file.length; i++) {
    formData.append("files", file[i]);
  }

  tags && typeof tags === "object" && tags.length > 0 && formData.append("tags", tags);

  const response = yield request({
    method: "post",
    formData,
    upload: true,
    url: link,
  });
  if (response.success) {
    yield put(manualUploadSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "File uploaded successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(manualUploadFailure("error"));
  }
}

export function* onUploadFile() {
  yield takeEvery(DataEntryActionTypes.MANUAL_UPLOAD_START, uploadFile);
}
