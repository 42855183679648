import { createSelector } from 'reselect';

const ProjectSelector = (state) => state.project;

export const selectprojects = createSelector(
  [ProjectSelector],
  (project) => project.projects
);

export const selectLoadingprojects = createSelector(
  [ProjectSelector],
  (project) => project.loadingProjects
);

export const selectUpdatingproject = createSelector(
  [ProjectSelector],
  (project) => project.updatingProject
);

export const selectCreatingproject = createSelector(
  [ProjectSelector],
  (project) => project.creatingProject
);

export const selectDeletingproject = createSelector(
  [ProjectSelector],
  (project) => project.deletingProject
);