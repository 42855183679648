import { put, takeEvery } from "redux-saga/effects";
import {
  renameDocumentFailure,
  renameDocumentSuccess,
} from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import DataEntryActionTypes from "../types";

const { API_BASE_URL } = urls || {};

function* renameDocument({ payload: { id, name, cb } }) {
  const link = `${API_BASE_URL}/documents/${id}/rename`;

  const data = {
    originalFilename: name,
  };

  const response = yield request({
    method: "patch",
    data,
    url: link,
  });
  if (response.success) {
    yield put(renameDocumentSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "Document renamed successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description:
        response?.message?.message || "Something went wrong try again",
      placement: "topRight",
    });
    yield put(renameDocumentFailure("error"));
  }
}

export function* onRenameDocument() {
  yield takeEvery(DataEntryActionTypes.RENAME_DOCUMENT_START, renameDocument);
}
