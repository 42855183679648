import { put, takeEvery } from "redux-saga/effects";
import { updateProjectSuccess, updateProjectFailure } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import ProjectActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* updateProjectManagers({
  payload: { id, projectManagersIDs, cb },
}) {
  const url = `${API_BASE_URL}/projects/${id}`;

  const response = yield request({
    method: "patch",
    data: {
      projectManagersIDs,
    },
    url,
  });

  if (response.success) {
    yield put(updateProjectSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "Project updated successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(updateProjectFailure("error"));
  }
}

export function* onUpdateProjectManagers() {
  yield takeEvery(
    ProjectActionTypes.UPDATE_PROJECT_MANAGERS_START,
    updateProjectManagers
  );
}
