const ProjectActionTypes = {
    SET_ANY_DATA: 'SET_ANY_DATA',
  
    FETCH_PROJECTS_START: 'FETCH_PROJECTS_START',
    FETCH_PROJECTS_SUCCESS: 'FETCH_PROJECTS_SUCCESS',
    FETCH_PROJECTS_ERROR: 'FETCH_PROJECTS_ERROR',

    FETCH_MYPROJECTS_START: 'FETCH_MYPROJECTS_START',
    FETCH_MYPROJECTS_SUCCESS: 'FETCH_MYPROJECTS_SUCCESS',
    FETCH_MYPROJECTS_ERROR: 'FETCH_MYPROJECTS_ERROR',

    CREATE_PROJECT_START: 'CREATE_PROJECT_START',
    CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_ERROR: 'CREATE_PROJECT_ERROR',

    UPDATE_PROJECT_START: 'UPDATE_PROJECT_START',
    UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
    UPDATE_PROJECT_ERROR: 'UPDATE_PROJECT_ERROR',

    UPDATE_PROJECT_MEMBERS_START: 'UPDATE_PROJECT_MEMBERS_START',
    UPDATE_PROJECT_MEMBERS_SUCCESS: 'UPDATE_PROJECT_MEMBERS_SUCCESS',
    UPDATE_PROJECT_MEMBERS_ERROR: 'UPDATE_PROJECT_MEMBERS_ERROR',

    UPDATE_PROJECT_MANAGERS_START: 'UPDATE_PROJECT_MANAGERS_START',
    UPDATE_PROJECT_MANAGERS_SUCCESS: 'UPDATE_PROJECT_MANAGERS_SUCCESS',
    UPDATE_PROJECT_MANAGERS_ERROR: 'UPDATE_PROJECT_MANAGERS_ERROR',

    DELETE_PROJECT_START: 'DELETE_PROJECT_START',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_ERROR: 'DELETE_PROJECT_ERROR',
  };
  
  export default ProjectActionTypes;
  