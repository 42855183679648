import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout";
import "./index.scss";
import { Input, Select as AntdSelect } from "antd";
import Pagination from "@mui/material/Pagination";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  selectLoadingprojects,
  selectprojects,
} from "../../../redux/project/selectors";
import Loader from "../../../components/Loader";
import { startFileDownload } from "../../../redux/document/actions";
import { startFetchProjects } from "../../../redux/project/actions";
import DocumentPreviewDialog from "../../../components/File-Viewer/documentPreviewDialog";
import ConfirmDocumentDeleteModal from "./modals/ConfirmDocumentDeleteModal";
import RenameDocumentModal from "./modals/RenameDocumentModal";
import {
  selectApprovingrequest,
  selectDecliningrequest,
  selectLoadingrequests,
  selectrequests,
} from "../../../redux/request/selectors";
import { EmptyState } from "../../../components/EmptyState";
import AuditDocumentRow from "./AuditDocumentRow";
import {
  approveRequestStart,
  startFetchRequests,
} from "../../../redux/request/actions";
import {
  selectDeletingDocument,
  selectRenamingDocument,
} from "../../../redux/document/selectors";
import DeclineRequestModal from "./modals/DeclineRequestModal";

const AuditApproval = () => {
  const dispatch = useDispatch();
  const approvalRequests = useSelector(selectrequests);

  const [preview, setPreview] = useState(null);
  const [declineRequestDialog, setDeclineRequestDialog] = useState(null);
  const [renameDocumentDialog, setRenameDocumentDialog] = useState(null);
  const [deleteDocumentDialog, setDeleteDocumentDialog] = useState(null);

  const [filterOn, setFilterOn] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [project, setProject] = useState(null);
  const projects = useSelector(selectprojects);
  const loadingRequests = useSelector(selectLoadingrequests);
  const loadingProjects = useSelector(selectLoadingprojects);
  const approvingRequest = useSelector(selectApprovingrequest);
  const decliningRequest = useSelector(selectDecliningrequest);
  const renamingDocument = useSelector(selectRenamingDocument);
  const deletingDocument = useSelector(selectDeletingDocument);

  const loading =
    loadingRequests ||
    loadingProjects ||
    approvingRequest ||
    decliningRequest ||
    renamingDocument ||
    deletingDocument;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("desc");

  useEffect(() => {
    callBack();
  }, [dispatch, page, limit, order, searchTerm, project]);

  const onSearch = (val) => {
    if (val) {
      setFilterOn(true);
    } else {
      setFilterOn(false);
    }
    setSearchTerm(val);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1); // Reset to first page when limit changes
  };

  const downloadFile = async (data) => {
    dispatch(startFileDownload(data.id, data.originalFilename));
  };

  const handleApproveRequest = async (data) => {
    dispatch(
      approveRequestStart(data.id, () => {
        callBack();
      })
    );
  };

  const callBack = () => {
    dispatch(startFetchRequests(page, limit, order, searchTerm));
    dispatch(startFetchProjects());
  };

  return (
    <Layout pageTitle="Audit & Approval">
      <div className="mt-4">
        <DeclineRequestModal
          title={"Decline Request"}
          open={!!declineRequestDialog}
          request={declineRequestDialog}
          closeModal={() => setDeclineRequestDialog(null)}
          callBack={callBack}
        />
        <ConfirmDocumentDeleteModal
          open={!!deleteDocumentDialog}
          document={deleteDocumentDialog}
          closeModal={() => setDeleteDocumentDialog(null)}
          callBack={callBack}
        />
        <RenameDocumentModal
          title="Rename Document"
          open={!!renameDocumentDialog}
          document={renameDocumentDialog}
          closeModal={() => setRenameDocumentDialog(null)}
          callBack={callBack}
        />
        {!!preview && preview.path && (
          <DocumentPreviewDialog
            open={!!preview}
            handleClose={() => setPreview(null)}
            document={preview}
          />
        )}
        {
          <div className="filter_bar">
            <div className="d-flex filter-inputs">
              <Input
                value={searchTerm}
                onChange={({ target: { value } }) => onSearch(value)}
                placeholder="Search documents by file name"
              />
              <AntdSelect
                allowClear
                style={{
                  width: "40%",
                }}
                onChange={(e) => {
                  setProject(e);
                  setFilterOn(e ? true : false);
                }}
                placeholder="Filter by Project"
              >
                {projects.data &&
                  (projects?.data || []).map((project, i) => (
                    <AntdSelect.Option key={i} value={project?.id}>
                      {project?.name.toUpperCase()}
                    </AntdSelect.Option>
                  ))}
              </AntdSelect>
            </div>
          </div>
        }
        {loading ? (
          <Loader />
        ) : approvalRequests?.data?.length < 1 ? (
          <EmptyState
            title={"No File available for Approval"}
            disabled={loading}
          />
        ) : (
          <div className="table_container mt-4">
            <table>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Submitted By</th>
                  <th>File Size</th>
                  <th>Project</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {approvalRequests &&
                  approvalRequests?.data &&
                  approvalRequests?.data.map((data, i) => (
                    <AuditDocumentRow
                      key={data.id}
                      row={data}
                      onPreviewClick={(request) =>
                        setPreview({
                          path: request?.document.path,
                          type: request?.document.type,
                        })
                      }
                      onDownloadClick={downloadFile}
                      onApproveClick={(request) =>
                        handleApproveRequest(request)
                      }
                      onDeclineClick={(request) => {
                        console.log(request);
                        setDeclineRequestDialog(request);
                      }}
                      onRenameClick={(request) =>
                        setRenameDocumentDialog(request.document)
                      }
                      onDeleteClick={(request) =>
                        setDeleteDocumentDialog(request.document)
                      }
                    />
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && approvalRequests?.data?.length ? (
          <div className="pagination mt-4">
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Pagination
                variant="outlined"
                count={approvalRequests?.meta?.lastPage || 0}
                page={page}
                onChange={handlePageChange}
                color="primary"
                shape="rounded"
              />
              <FormControl
                variant="outlined"
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <InputLabel>Items per page</InputLabel>
                <Select
                  value={limit}
                  onChange={handleLimitChange}
                  label="Items per page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default AuditApproval;
