import { put, takeEvery } from "redux-saga/effects";
import {
  declineRequestSuccess,
  declineRequestFailure,
  startFetchRequests,
} from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import RequestActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* declineRequest({ payload: { id, message, cb } }) {
  const url = `${API_BASE_URL}/approval-requests/${id}/decline`;

  const response = yield request({
    method: "patch",
    url,
    body: {
      disapprovalReason: message,
    },
  });
  if (response.success) {
    yield put(declineRequestSuccess());
    yield put(startFetchRequests());
    cb();
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(declineRequestFailure("error"));
  }
}

export function* onDeclineRequest() {
  yield takeEvery(RequestActionTypes.DECLINE_REQUEST_START, declineRequest);
}
