import axios from "axios";

const fetch = (options) => {
  const { method = "get", data, url, responseType } = options;

  const authToken = sessionStorage.getItem("access_token");
  // eslint-disable-next-line no-unused-expressions
  axios.defaults.headers = {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    Authorization: `Bearer ${authToken}`,
  };
  // authToken &&
  //   (axios.defaults.headers.common.Authorization = `Bearer ${authToken}`);

  switch (method.toLowerCase()) {
    case "get":
      return axios.get(url, {
        params: data,
        responseType,
      });
    case "delete":
      return axios.delete(url, {
        data,
      });
    case "post":
      switch (options.upload) {
        case true:
          return axios.post(url, options.formData, options.config);
        default:
          return axios.post(url, data);
      }
    case "put":
      return axios.put(url, data);
    case "patch":
      return axios.patch(url, data);
    case "download":
      return axios.get(url, {
        params: data,
        responseType,
      });
    default:
      return axios(options);
  }
};

export default function request(options) {
  return fetch(options)
    .then((response) => {
      const { data, status } = response;
      return {
        success: true,
        raw: data,
        statusCode: status,
      };
    })
    .catch((err) => {
      const { response } = err;
      let msg;
      let statusCode;
      if (response && response instanceof Object) {
        const { data, statusText } = response;
        statusCode = response.status;

        msg = data.error || statusText;

        if (statusCode === 401) {
          // Handle 401 Unauthorized: Log out and redirect to login
          sessionStorage.clear(); // Clear session storage
          window.location.href = "/"; // Redirect to the login page
          return; // Stop further processing
        }
      } else {
        statusCode = 600;
        msg = "Poor internet connections.";
      }
      return { success: false, statusCode, message: msg };
    });
}
