import React, { useState } from "react";
import OTPInput from "otp-input-react";
import { useFormik } from "formik";
import { notification } from "antd";
import * as Yup from "yup";
import "../../Pages/Signin/Signin.scss";
import axios from "axios";
import urls from "../../utils/config";

const validationSchema = Yup.object().shape({
  code: Yup.string().required("OTP is required"),
});

const { API_BASE_URL } = urls || {};

const VerifyOtp = ({ account }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: (values) => {
      verifyOtp(values);
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
  });

  const verifyOtp = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${API_BASE_URL}/auth/${
          account === "system_admin"
            ? "signin-admin/verify-newdevice-login"
            : "signin-staff/verify-newdevice-login"
        }`,
        data
      );
      if (res.status === 200) {
        sessionStorage.setItem("access_token", res.data.accessToken);
        sessionStorage.setItem("user_type", res.data.userType);
        notification.success({
          message: `Success!!!`,
          description: "Logged in Successfully",
          placement: "topRight",
        });
        window.location.reload();
      }
      setLoading(false);
    } catch (err) {
      notification.error({
        message: `Error`,
        description:
          err.response.data.message || "Something went wrong try again",
        placement: "topRight",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="form__box">
        <div className="header">
          <h3>Kindly enter your verification code</h3>
        </div>
        <div className="form__body">
          <div className="form">
            <div className="input__container">
              <label>
                To log in, kindly enter the code sent to your email address
              </label>
              <div className="otp__box">
                <OTPInput
                  value={formik.values.code}
                  onChange={(e) => formik.setFieldValue("code", e)}
                  name="otp"
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                />
              </div>
              <p className="error">{formik.errors.password}</p>
            </div>
            <div className="input__container">
              <button
                type="button"
                onClick={formik.handleSubmit}
                disabled={
                  formik.values.code.length < 4 || loading ? true : false
                }
              >
                {loading ? (
                  <i className="icon-spin animate-spin" />
                ) : (
                  "Log Into your account"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
