import React, { useState } from "react";
import "./Signin.scss";
import { NavLink } from "react-router-dom";
import VerifyOtp from "../../components/Auth/VerifyOTP";
// import SigninComp from "../../components/Auth/SignIn";
import UserSignin from "../../components/Auth/UserSignIn";
import PersonOffRoundedIcon from "@mui/icons-material/PersonOffRounded";

const Signin = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [account, setAccount] = useState("");

  return (
    <div className="signin-container">
      <div className="d-flex align-items-center justify-content-between mb-4 top_bar"></div>

      {showOtp ? (
        <VerifyOtp account={account} />
      ) : (
        <div className="form__container mb-2">
          <UserSignin setShowOtp={setShowOtp} setAccount={setAccount} />
        </div>
      )}

      <div className="forgot__password">
        <p>
          Forgot password?{" "}
          <span>
            <NavLink to="/forgot-password">Recover now</NavLink>
          </span>
        </p>
      </div>
      <NavLink
        to="/public"
        className={({ isActive }) => (isActive ? "active" : "")}
      >
        <div
          style={{
            color: "#000",
          }}
        >
          <PersonOffRoundedIcon sx={{ mr: 1 }} />
          <span>Guest Access</span>
        </div>
      </NavLink>
    </div>
  );
};

export default Signin;
