import { put, takeEvery } from "redux-saga/effects";
import { deleteFolderSuccess, deleteFolderFailure, startFetchFolders } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import FolderActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* deleteFolder({ payload: { id, cb } }) {
  const url = `${API_BASE_URL}/admin/folders/${id}`;

  const response = yield request({
    method: "delete",
    url,
  });
  if (response.success) {
    yield put(deleteFolderSuccess());
    yield put(startFetchFolders());
    cb();
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(deleteFolderFailure("error"));
  }
}

export function* onDeleteFolder() {
  yield takeEvery(FolderActionTypes.DELETE_FOLDER_START, deleteFolder);
}
