import React from 'react';
import { Chip, Avatar, Tooltip } from '@mui/material';

// Predefined set of Google-like colors
const googleColors = [
  '#4285F4',
  '#DB4437',
  '#F4B400',
  '#0F9D58',
  '#673ab7',
];

// Function to select a color from the predefined set
const getColorFromInitials = (initials) => {
  const charCodeSum = initials.charCodeAt(0) + initials.charCodeAt(1);
  return googleColors[charCodeSum % googleColors.length];
};

// Function to generate the avatar's initials with Google-like colors
const stringAvatar = (name) => {
  const initials = name.split(' ').map(word => word[0]).join('');
  return {
    sx: {
      bgcolor: getColorFromInitials(initials),
      width: 24, // Smaller width
      height: 24, // Smaller height
      fontSize: 12, // Smaller font size
    },
    children: initials,
  };
};

export const ManagersChipList = ({ managers }) => {
  return (
    <>
      {managers.length > 0 ? (
        managers.map((manager) => {
          const name = `${manager.firstName} ${manager.lastName}`;

          return (
            <Tooltip title={name} key={manager.id}>
              <Chip
                label={name}
                variant="filled"
                size="medium"
                sx={{
                  margin: 0.5,
                  '& .MuiChip-label': {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Tooltip>
          );
        })
      ) : (
        "N/A"
      )}
    </>
  );
};
