import FolderActionTypes from "./types";

// set any data
export const setAnyFolderData = (payload) => ({
  type: FolderActionTypes.SET_ANY_DATA,
  payload
});

// fetch folders
export const startFetchFolders = () => ({
  type: FolderActionTypes.FETCH_FOLDERS_START,
});

export const fetchFoldersSuccess = (folders) => ({
  type: FolderActionTypes.FETCH_FOLDERS_SUCCESS,
  payload: folders,
});

export const fetchFoldersFailure = (error) => ({
  type: FolderActionTypes.FETCH_FOLDERS_ERROR,
  payload: error,
});

// create folder
export const startCreateFolder = (folder_name, cb) => ({
  type: FolderActionTypes.CREATE_FOLDER_START,
  payload: { folder_name, cb }
});

export const createFolderSuccess = () => ({
  type: FolderActionTypes.CREATE_FOLDER_SUCCESS
});

export const createFolderFailure = (error) => ({
  type: FolderActionTypes.CREATE_FOLDER_ERROR,
  payload: error,
});

// update folder
export const updateFolderStart = (id, name, cb) => ({
  type: FolderActionTypes.UPDATE_FOLDER_START,
  payload: { id, name, cb },
});

export const updateFolderSuccess = () => ({
  type: FolderActionTypes.UPDATE_FOLDER_SUCCESS,
});

export const updateFolderFailure = (error) => ({
  type: FolderActionTypes.UPDATE_FOLDER_ERROR,
  payload: error,
});

// update folder
export const deleteFolderStart = (id, cb) => ({
  type: FolderActionTypes.DELETE_FOLDER_START,
  payload: { id, cb },
});

export const deleteFolderSuccess = () => ({
  type: FolderActionTypes.DELETE_FOLDER_SUCCESS,
});

export const deleteFolderFailure = (error) => ({
  type: FolderActionTypes.DELETE_FOLDER_ERROR,
  payload: error,
});