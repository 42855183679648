import Image from "../assets/images/image-gallery.png";
import Csv from "../assets/images/csv.png";
import Plain from "../assets/images/txt.png";
import Pdf from "../assets/images/pdf.png";
import Xlsx from "../assets/images/xls.png";
import Docs from "../assets/images/docx.png";
import Ppt from "../assets/images/ppt.png";
import PDF from "../assets/images/filetypes/pdf.svg";
import DOCS from "../assets/images/filetypes/docx.svg";
import EXCEL from "../assets/images/filetypes/excel.svg";

const fileFormats = {
  "image/webp": Image,
  "image/jpeg": Image,
  "image/png": Image,
  "image/jpg": Image,
  "text/csv": Csv,
  "text/plain": Plain,
  "application/pdf": PDF,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": EXCEL,
  "application/vnd.ms-excel": EXCEL,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    DOCS,
  "application/msword": DOCS,
  "application/vnd.ms-powerpoint": Ppt,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    Ppt,
  "application/octet-stream": Ppt,
};

export const checkFileFormat = (format) => {
  return fileFormats[format];
};

export const removeFileExtension = (filename) => {
  return filename.replace(/\.[^/.]+$/, "");
};