import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';

const DocumentPreviewDialog = ({ open, handleClose, document }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  if (!document) return null;

  // Google Docs Viewer URL
  const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(document.path)}&embedded=true`;

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
    setError(true);
  };

  const handleRetry = () => {
    setLoading(true);
    setError(false);
    // Trigger iframe reload
    document.getElementById('document-iframe').src = googleDocsViewerUrl;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Preview Document</DialogTitle>
      <DialogContent>
        <Box sx={{ height: '80vh', position: 'relative' }}>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {error ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography color="error" sx={{ marginBottom: 2 }}>
                Failed to load the document.
              </Typography>
              <Button onClick={handleRetry} variant="outlined">
                Retry
              </Button>
            </Box>
          ) : (
            <iframe
              id="document-iframe"
              src={googleDocsViewerUrl}
              style={{ width: '100%', height: '100%', border: 'none' }}
              title="Document Preview"
              onLoad={handleLoad}
              onError={handleError}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentPreviewDialog;
