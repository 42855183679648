import { put, takeEvery } from "redux-saga/effects";
import {
  fetchUploadedManualFiles,
  deleteDocumentSuccess,
  deleteDocumentFailure,
} from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import DataEntryActionTypes from "../types";

const { API_BASE_URL } = urls || {};

function* deleteDocument({ payload: { id, cb } }) {
  const link = `${API_BASE_URL}/documents/${id}`;

  const response = yield request({
    method: "delete",
    url: link,
  });
  if (response.success) {
    yield put(deleteDocumentSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "Document deleted successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description:
        response?.message?.message || "Something went wrong try again",
      placement: "topRight",
    });
    yield put(deleteDocumentFailure("error"));
  }
}

export function* onDeleteDocument() {
  yield takeEvery(DataEntryActionTypes.DELETE_DOCUMENT_START, deleteDocument);
}
