import React, { useState } from "react";
import { Input, notification } from "antd";
import "./ForgotPass.scss";
import {
  useParams,
  useNavigate,
  useSearchParams,
  NavLink,
} from "react-router-dom";
import StateLogo from "../../assets/images/logo/kano.png";
import StateMap from "../../assets/images/map/kano.png";
import urls from "../../utils/config";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Enter Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character e.g @,#"
    ),
  confirmPassword: Yup.string()
    .required("Confirm your Password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Confirm password does not match password"
      ),
    }),
});

const { API_BASE_URL } = urls || {};

const ResetPassword = () => {
  const { token } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("u");
  console.log(token);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      submit({ newPassword: values.password, token, userId });
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
  });

  const submit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${API_BASE_URL}/auth/password-reset/reset`,
        data
      );
      if (res.status === 200 || res.status === 201) {
        notification.success({
          message: `Success`,
          description: `Password reset Successful`,
          placement: "topRight",
        });
        formik.setValues(formik.initialValues);
        navigate("/");
      }
      setLoading(false);
    } catch (err) {
      notification.error({
        message: `Error`,
        description:
          err.response.data.error.message || "Something went wrong try again",
        placement: "topRight",
      });
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <>
        <div className="form__box">
          <div className="header">
            <h3>Reset Password</h3>
          </div>
          <div className="form__body">
            <div className="form">
              <div className="input__container">
                <label htmlFor="email">New Password</label>
                <Input.Password
                  name="password"
                  placeholder="New Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                <p className="error">{formik.errors.password}</p>
              </div>
              <div className="input__container">
                <label htmlFor="email">Confirm Password</label>
                <Input.Password
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                />
                <p className="error">{formik.errors.confirmPassword}</p>
              </div>
              <div className="input__container">
                <button disabled={loading} onClick={formik.handleSubmit}>
                  {loading ? (
                    <i className="icon-spin animate-spin" />
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="forgot__password">
          <p>
            Remember your password?{" "}
            <span>
              <NavLink to="/">Log in</NavLink>
            </span>
          </p>
        </div>
      </>
    </div>
  );
};

export default ResetPassword;
