import SearchActionTypes from "./types";

// set any data
export const setAnySearchData = (payload) => ({
  type: SearchActionTypes.SET_ANY_DATA,
  payload,
});

// fetch projects
export const startFetchSearchResults = (query, isPublic) => ({
  type: SearchActionTypes.FETCH_SEARCH_START,
  payload: { q: query, isPublic },
});

export const fetchSearchResultsSuccess = (searchResults) => ({
  type: SearchActionTypes.FETCH_SEARCH_SUCCESS,
  payload: searchResults,
});

export const fetchSearchResultsFailure = (error) => ({
  type: SearchActionTypes.FETCH_SEARCH_ERROR,
  payload: error,
});
