import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "../Pages/Signin";
import ForgotPassword from "../Pages/Forgot-password";
import AuditApproval from "../Pages/Admin/AuditApproval";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import DocumentArchives from "../Pages/Admin/DataEntry";
import ResetPassword from "../Pages/Forgot-password/reset-password";
import ProjectDocuments from "../Pages/Admin/ProjectDocuments";
import Projects from "../Pages/Admin/Projects";
import SearchOverview from "../Pages/Admin/Search";
import PublicSearchOverview from "../Pages/Admin/PublicSearch";
import UserManagement from "../Pages/Admin/Users";

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<Signin />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/public" element={<PublicSearchOverview />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/overview" element={<SearchOverview />} exact />
          <Route path="/data-entry" element={<DocumentArchives />} />
          <Route path="/project-documents" element={<ProjectDocuments />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/audit-approval" element={<AuditApproval />} />
          <Route path="/user-management" element={<UserManagement />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
