import { put, takeEvery } from "redux-saga/effects";
import {
  createProjectSuccess,
  createProjectFailure,
} from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import ProjectActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* createProject({
  payload: { name, description, category, cb },
}) {
  const url = `${API_BASE_URL}/projects`;

  const response = yield request({
    method: "post",
    data: {
      name,
      description,
      category,
    },
    url,
  });
  if (response.success) {
    yield put(createProjectSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "Project created successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(createProjectFailure("error"));
  }
}

export function* onCreateProject() {
  yield takeEvery(ProjectActionTypes.CREATE_PROJECT_START, createProject);
}
