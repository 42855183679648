import { put, takeEvery } from "redux-saga/effects";
import {
  createRequestSuccess,
  createRequestFailure,
  startFetchRequests,
} from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import RequestActionTypes from "../types";
import {
  fetchMyUploadedFiles,
} from "../../document/actions";

const { API_BASE_URL } = urls || {};

export function* createRequest({ payload: { documentId, projectId, cb } }) {
  const url = `${API_BASE_URL}/approval-requests`;

  const response = yield request({
    method: "post",
    data: {
      documentId,
      projectId,
    },
    url,
  });
  if (response.success) {
    yield put(createRequestSuccess());
    yield put(fetchMyUploadedFiles());

    cb();
  } else {
    notification.error({
      message: `Error`,
      description:
        response?.message?.message || "Something went wrong try again",
      placement: "topRight",
    });
    yield put(createRequestFailure("error"));
  }
}

export function* onCreateRequest() {
  yield takeEvery(RequestActionTypes.CREATE_REQUEST_START, createRequest);
}
