import { createSelector } from 'reselect';

const RequestSelector = (state) => state.request;

export const selectrequests = createSelector(
  [RequestSelector],
  (request) => request.requests
);

export const selectLoadingrequests = createSelector(
  [RequestSelector],
  (request) => request.loadingRequests
);

export const selectApprovingrequest = createSelector(
  [RequestSelector],
  (request) => request.approvingRequest
);

export const selectDecliningrequest = createSelector(
  [RequestSelector],
  (request) => request.decliningRequest
);

export const selectCreatingrequest = createSelector(
  [RequestSelector],
  (request) => request.creatingRequest
);

export const selectDeletingrequest = createSelector(
  [RequestSelector],
  (request) => request.deletingRequest
);