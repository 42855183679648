import { put, takeEvery } from "redux-saga/effects";
import { fetchUsersSuccess, fetchUsersFailure } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import UserActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* fetchAllUsers(action) {
  const { page, limit, order, search, role } = action?.payload;

  const queryParams = {
    page,
    limit,
    order,
  };

  // Conditionally add `role` to the query parameters
  if (role && role !== "") {
    queryParams["role"] = role;
  }

  if (search && search !== "") {
    queryParams["search"] = search;
  }

  const queryString = new URLSearchParams(queryParams).toString();

  const url = `${API_BASE_URL}/users?${queryString}`;

  const response = yield request({
    method: "get",
    url,
  });
  if (response.success) {
    yield put(fetchUsersSuccess(response?.raw));
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(fetchUsersFailure("error"));
  }
}

export function* onGetAllUsers() {
  yield takeEvery(UserActionTypes.FETCH_USER_START, fetchAllUsers);
}
