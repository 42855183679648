import RequestActionTypes from "./types";

// set any data
export const setAnyRequestData = (payload) => ({
  type: RequestActionTypes.SET_ANY_DATA,
  payload,
});

// fetch requests
export const startFetchRequests = (
  page = 1,
  limit = 50,
  order = "asc",
  search
) => ({
  type: RequestActionTypes.FETCH_REQUESTS_START,
  payload: { page, limit, order, search },
});

export const fetchRequestsSuccess = (requests) => ({
  type: RequestActionTypes.FETCH_REQUESTS_SUCCESS,
  payload: requests,
});

export const fetchRequestsFailure = (error) => ({
  type: RequestActionTypes.FETCH_REQUESTS_ERROR,
  payload: error,
});

// create request
export const startCreateRequest = (documentId, projectId, cb) => ({
  type: RequestActionTypes.CREATE_REQUEST_START,
  payload: { documentId, projectId, cb },
});

export const createRequestSuccess = () => ({
  type: RequestActionTypes.CREATE_REQUEST_SUCCESS,
});

export const createRequestFailure = (error) => ({
  type: RequestActionTypes.CREATE_REQUEST_ERROR,
  payload: error,
});

// update request
export const updateRequestStart = (id, name, cb) => ({
  type: RequestActionTypes.UPDATE_REQUEST_START,
  payload: { id, name, cb },
});

export const updateRequestSuccess = () => ({
  type: RequestActionTypes.UPDATE_REQUEST_SUCCESS,
});

export const updateRequestFailure = (error) => ({
  type: RequestActionTypes.UPDATE_REQUEST_ERROR,
  payload: error,
});

// approve request
export const approveRequestStart = (id, cb) => ({
  type: RequestActionTypes.APPROVE_REQUEST_START,
  payload: { id, cb },
});

export const approveRequestSuccess = () => ({
  type: RequestActionTypes.APPROVE_REQUEST_SUCCESS,
});

export const approveRequestFailure = (error) => ({
  type: RequestActionTypes.APPROVE_REQUEST_ERROR,
  payload: error,
});

// decline request
export const declineRequestStart = (id, message, cb) => ({
  type: RequestActionTypes.DECLINE_REQUEST_START,
  payload: { id, message, cb },
});

export const declineRequestSuccess = () => ({
  type: RequestActionTypes.DECLINE_REQUEST_SUCCESS,
});

export const declineRequestFailure = (error) => ({
  type: RequestActionTypes.DECLINE_REQUEST_ERROR,
  payload: error,
});

// update request
export const deleteRequestStart = (id, cb) => ({
  type: RequestActionTypes.DELETE_REQUEST_START,
  payload: { id, cb },
});

export const deleteRequestSuccess = () => ({
  type: RequestActionTypes.DELETE_REQUEST_SUCCESS,
});

export const deleteRequestFailure = (error) => ({
  type: RequestActionTypes.DELETE_REQUEST_ERROR,
  payload: error,
});
