import { put, takeEvery } from "redux-saga/effects";
import {
  fetchSearchResultsSuccess,
  fetchSearchResultsFailure,
} from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import SearchActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* fetchSearchResults(action) {
  const { q, isPublic } = action?.payload;
  let url;

  const queryParams = {
    q,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  if (isPublic) {
    url = `${API_BASE_URL}/documents/public-search?${queryString}`;
  } else {
    url = `${API_BASE_URL}/documents/search?${queryString}`;
  }

  const response = yield request({
    method: "get",
    url,
  });
  if (response.success) {
    yield put(fetchSearchResultsSuccess(response?.raw));
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(fetchSearchResultsFailure("error"));
  }
}

export function* onFetchSearchResults() {
  yield takeEvery(SearchActionTypes.FETCH_SEARCH_START, fetchSearchResults);
}
