import RoutesComponent from "./Routes";
import "rc-pagination/assets/index.css";
import "./index.scss";

function App() {
  return (
    <div className="App">
      <RoutesComponent />
    </div>
  );
}

export default App;
