const UserActionTypes = {
    SET_ANY_DATA: 'SET_ANY_DATA',

    CREATE_USER_START: 'CREATE_USER_START',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_ERROR: 'CREATE_USER_ERROR',

    FETCH_MEMBERS_START: 'FETCH_MEMBERS_START',
    FETCH_MEMBERS_SUCCESS: 'FETCH_MEMBERS_SUCCESS',
    FETCH_MEMBERS_ERROR: 'FETCH_MEMBERS_ERROR',

    FETCH_USER_START: 'FETCH_USER_START',
    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    FETCH_USER_ERROR: 'FETCH_USER_ERROR',

    UPDATE_USER_START: 'UPDATE_USER_START',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

    ACTIVATE_USER_START: 'ACTIVATE_USER_START',
    ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
    ACTIVATE_USER_ERROR: 'ACTIVATE_USER_ERROR',
    
    DEACTIVATE_USER_START: 'DEACTIVATE_USER_START',
    DEACTIVATE_USER_SUCCESS: 'DEACTIVATE_USER_SUCCESS',
    DEACTIVATE_USER_ERROR: 'DEACTIVATE_USER_ERROR',

    PASSWORD_RESET_START: 'PASSWORD_RESET_START',
    PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
    PASSWORD_RESET_ERROR: 'PASSWORD_RESET_ERROR',
    
    DELETE_USER_START: 'DELETE_USER_START',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  };
  
  export default UserActionTypes;
  