import { Avatar, AvatarGroup, Tooltip } from "@mui/material";

// Expanded set of Google-like colors
const googleColors = [
  "#673ab7", // Deep Purple
  "#DB4437", // Red
  "#0F9D58", // Green
  "#4285F4", // Blue
  "#F4B400", // Yellow
  "#FF5722", // Orange
  "#795548", // Brown
  "#607D8B", // Blue Grey
  "#009688", // Teal
  "#8E24AA", // Purple
  "#3F51B5", // Indigo
  "#E91E63", // Pink
];

// Function to hash the name and map to a color
const getColorFromName = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash % googleColors.length);
  return googleColors[index];
};

// Function to generate the avatar's initials with a mapped color
export const stringAvatar = (name) => {
  const initials = name
    .split(" ")
    .map((word) => word[0])
    .join("");
  return {
    sx: {
      bgcolor: getColorFromName(name),
      width: 36, // Smaller width
      height: 36, // Smaller height
      fontSize: 16,
    },
    children: initials,
  };
};

export const AvatarGroupComponent = ({ members }) => {
  return (
    <AvatarGroup max={8} sx={{ direction: "rtl" }}>
      {members.map((member) => {
        const name = `${member.firstName} ${member.lastName}`;

        return (
          <Tooltip title={name} key={member.id}>
            <Avatar {...stringAvatar(name)} />
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );
};
