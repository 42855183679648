import { put, takeEvery } from "redux-saga/effects";
import {
  makeDocumentPublicFailure,
  makeDocumentPublicSuccess,
} from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import DataEntryActionTypes from "../types";

const { API_BASE_URL } = urls || {};

function* makeDocumentPublic({ payload: { id, cb } }) {
  const link = `${API_BASE_URL}/documents/${id}/public`;

  const response = yield request({
    method: "patch",
    url: link,
  });
  if (response.success) {
    yield put(makeDocumentPublicSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "Document visibility changed successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description:
        response?.message?.message || "Something went wrong try again",
      placement: "topRight",
    });
    yield put(makeDocumentPublicFailure("error"));
  }
}

export function* onMakeDocumentPublic() {
  yield takeEvery(DataEntryActionTypes.MAKE_FILE_PUBLIC_START, makeDocumentPublic);
}
