import { all, call } from "redux-saga/effects";
import dataEntrySaga from "./document/saga";
import userSaga from "./users/saga";
import folderSaga from "./folder/saga";
import projectSaga from "./project/saga";
import requestSaga from "./request/saga";
import searchSaga from "./search/saga";

export default function* rootSaga() {
  yield all([
    call(dataEntrySaga),
    call(userSaga),
    call(folderSaga),
    call(projectSaga),
    call(requestSaga),
    call(searchSaga),
  ]);
}
