import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal/modal";
import "./style.scss";
import { TextField, Box, Typography, Button, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LoopIcon from "@mui/icons-material/Loop";
import { selectDecliningrequest } from "../../../../../redux/request/selectors";
import { declineRequestStart } from "../../../../../redux/request/actions";
import Textarea from "@mui/joy/Textarea";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import FormatBold from "@mui/icons-material/FormatBold";
import FormatItalic from "@mui/icons-material/FormatItalic";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Check from "@mui/icons-material/Check";

const DeclineRequestModal = ({
  open,
  closeModal,
  title,
  request,
  callBack,
}) => {
  const dispatch = useDispatch();
  const declining = useSelector(selectDecliningrequest);
  const [disapprovalReason, setDisapprovalReason] = useState("");

  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState("normal");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDecline = () => {
    if (request && disapprovalReason.trim()) {
      dispatch(
        declineRequestStart(request?.id, disapprovalReason.trim(), () => {
          reset();
          callBack && callBack();
        })
      );
    }
  };

  const reset = () => {
    setDisapprovalReason("");
    closeModal();
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={title}
      modalFooter={
        <div className="modal_footer">
          <Button
            className="cancel me-2"
            onClick={reset}
            disabled={declining}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDecline}
            disabled={!disapprovalReason.trim() || declining}
            variant="contained"
            endIcon={
              declining ? (
                <LoopIcon className="animate-spin" fontSize="medium" />
              ) : (
                <EditIcon fontSize="medium" />
              )
            }
          >
            Decline
          </Button>
        </div>
      }
      style={{ maxWidth: "500px" }} // Adjust size here
    >
      <Box>
        <Textarea
          placeholder="Type reason for declining the request here…"
          minRows={5}
          value={disapprovalReason}
          onChange={(e) => setDisapprovalReason(e.target.value)}
          endDecorator={
            <Box
              sx={{
                display: "flex",
                gap: "var(--Textarea-paddingBlock)",
                pt: "var(--Textarea-paddingBlock)",
                borderTop: "1px solid",
                borderColor: "divider",
                flex: "auto",
              }}
            >
              <IconButton
                variant="plain"
                color="neutral"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <FormatBold />
                <KeyboardArrowDown fontSize="md" />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                size="sm"
                placement="bottom-start"
                sx={{ "--ListItemDecorator-size": "24px" }}
              >
                {["200", "normal", "bold"].map((weight) => (
                  <MenuItem
                    key={weight}
                    selected={fontWeight === weight}
                    onClick={() => {
                      setFontWeight(weight);
                      setAnchorEl(null);
                    }}
                    sx={{ fontWeight: weight }}
                  >
                    <ListItemDecorator>
                      {fontWeight === weight && <Check fontSize="sm" />}
                    </ListItemDecorator>
                    {weight === "200" ? "lighter" : weight}
                  </MenuItem>
                ))}
              </Menu>
              <IconButton
                variant={italic ? "soft" : "plain"}
                color={italic ? "primary" : "neutral"}
                aria-pressed={italic}
                onClick={() => setItalic((bool) => !bool)}
              >
                <FormatItalic />
              </IconButton>
            </Box>
          }
          sx={[
            {
              minWidth: 300,
              fontWeight,
            },
            {
              marginBottom: 2,
            },
            italic ? { fontStyle: "italic" } : { fontStyle: "initial" },
          ]}
        />
        {!disapprovalReason.trim() && (
          <Alert severity="warning">
            Please provide a reason for declining the request.
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default DeclineRequestModal;
