import UserActionTypes from "./types";

const initialState = {
  users: [],
  members: [],
  loadingMembers: false,
  loadingUsers: true,
  creatingUser: false,
  updatingUser: false,
  activatingUser: false,
  deactivatingUser: false,
  deletingUser: false,
  resettingPassword: false,
  error: null,
};

function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case UserActionTypes.SET_ANY_DATA:
      return {
        ...state,
        ...payload,
      };

    case UserActionTypes.FETCH_USER_START:
      return {
        ...state,
        loadingUsers: true,
      };
    case UserActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        loadingUsers: false,
        users: payload,
      };
    case UserActionTypes.FETCH_USER_ERROR:
      return {
        ...state,
        loadingUsers: false,
        error: payload,
      };

    // Create user
    case UserActionTypes.CREATE_USER_START:
      return {
        ...state,
        creatingUser: true,
      };
    case UserActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        creatingUser: false,
      };
    case UserActionTypes.CREATE_USER_ERROR:
      return {
        ...state,
        creatingUser: false,
        error: payload,
      };

    // Update user
    case UserActionTypes.UPDATE_USER_START:
      return {
        ...state,
        updatingUser: true,
      };
    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatingUser: false,
      };
    case UserActionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        updatingUser: false,
        error: payload,
      };

    // Activate user
    case UserActionTypes.ACTIVATE_USER_START:
      return {
        ...state,
        activatingUser: true,
      };
    case UserActionTypes.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activatingUser: false,
      };
    case UserActionTypes.ACTIVATE_USER_ERROR:
      return {
        ...state,
        activatingUser: false,
        error: payload,
      };

    // Deactivate user
    case UserActionTypes.DEACTIVATE_USER_START:
      return {
        ...state,
        deactivatingUser: true,
      };
    case UserActionTypes.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        deactivatingUser: false,
      };
    case UserActionTypes.DEACTIVATE_USER_ERROR:
      return {
        ...state,
        deactivatingUser: false,
        error: payload,
      };

    // Reset password
    case UserActionTypes.PASSWORD_RESET_START:
      return {
        ...state,
        resettingPassword: true,
      };
    case UserActionTypes.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
      };
    case UserActionTypes.PASSWORD_RESET_ERROR:
      return {
        ...state,
        resettingPassword: false,
        error: payload,
      };

    // Delete user
    case UserActionTypes.DELETE_USER_START:
      return {
        ...state,
        deletingUser: true,
      };
    case UserActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        deletingUser: false,
      };
    case UserActionTypes.DELETE_USER_ERROR:
      return {
        ...state,
        deletingUser: false,
        error: payload,
      };

    // Members
    case UserActionTypes.FETCH_MEMBERS_START:
      return {
        ...state,
        loadingMembers: true,
      };
    case UserActionTypes.FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        loadingMembers: false,
        members: payload,
      };
    case UserActionTypes.FETCH_MEMBERS_ERROR:
      return {
        ...state,
        loadingMembers: false,
        error: payload,
      };

    default:
      return state;
  }
}

export default userReducer;
